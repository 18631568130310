import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function SetPinModal({ setShowSetPinModal, getProfileData }) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const [showConfirm, setShowConfirm] = useState(false);

  const onSubmit = (data) => {
    setLoading("loading");
    const token = localStorage.getItem("giftToken");

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API}api/v1/pin_update`,
      headers: { Authorization: `Bearer ${token}` },
      data: { ...data },
    };

    axios(config)
      .then(function (response) {
        setLoading("");
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowSetPinModal(false);
        getProfileData();
      })
      .catch(function (error) {
        if (error?.response?.data?.error) {
          setLoading("");
          toast.error(error?.response?.data?.error[0], {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        } else {
          setLoading("");
          toast.error(error?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  return (
    <>
      {/* <label htmlFor="my-modal-5" className="btn modal-button">open modal</label> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <input type="checkbox" id="my-modal-13" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box w-11/12 max-w-[662px] h-[650px]">
          <label for="my-modal-13" class="   absolute left-2 top-2">
            <img src="/arrow-right.svg" className="h-8 w-8 font-bold" />
          </label>
          <div className="font-black text-center text-gray-400 py-4 text-2xl">
            Set Your Pin
          </div>
          <div></div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="py-5">
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    PIN
                  </label>
                  <img
                    src="/eye-icon.svg"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-5 top-[64%] w-8 h-8 transform -translate-y-1/2"
                  />

                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-email"
                    type={!showPassword ? "password" : "text"}
                    placeholder="Enter New Pin"
                    required
                    {...register("txpin")}
                  />
                  <div className="absolute text-right"></div>
                </div>
              </div>

              <div class="flex   flex-wrap -mx-4 mb-2">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    CONFIRM PIN
                  </label>
                  <img
                    src="/eye-icon.svg"
                    onClick={() => setShowConfirm(!showConfirm)}
                    className="absolute right-5 top-[64%] w-8 h-8 transform -translate-y-1/2"
                  />

                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-email"
                    type={!showConfirm ? "password" : "text"}
                    placeholder="Confirm Pin"
                    required
                    {...register("txpin_confirmation")}
                  />
                  <div className="absolute text-right"></div>
                </div>
              </div>

              {/* <div class="flex   flex-wrap -mx-4 mb-2">
              <div class="w-full relative px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-city"
                >
                  EMAIL VERIFICATION CODE
                </label>

                <input
                  class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                  id="grid-email"
                  type={!showPassword ? "password" : "text"}
                  placeholder="Email  Confirmation Code"
                />
              </div>
            </div> */}
            </div>
            <div className="text-center w-full">
              <button className={`${loading} btn  lg:w-1/2 w-full`}>
                Update
              </button>
            </div>
          </form>

          {/* <div className="modal-action">
            <label htmlFor="my-modal-5" className="btn">
              Yay!
            </label>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default SetPinModal;
