import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function ViewBankDetails({ getBankDetail, bankDetail }) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  // const [bankDetail, setBankDetail] = useState([]);

  // useEffect(() => {
  //   getBankDetail();
  // }, []);

  // const getBankDetail = () => {
  //   const token = localStorage.getItem("giftToken");

  //   var config = {
  //     method: "get",
  //     url: `${process.env.REACT_APP_API}api/v1/list_customers_bank`,
  //     headers: { Authorization: `Bearer ${token}` },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       setBankDetail(response.data?.data?.data);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };
  return (
    <>
      {/* <label htmlFor="my-modal-5" className="btn modal-button">open modal</label> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <input type="checkbox" id="my-modal-30" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box w-11/12 max-w-[662px] h-[650px]">
          <label for="my-modal-30" class="   absolute left-2 top-2">
            <img src="/arrow-right.svg" className="h-8 w-8 font-bold" />
          </label>
          <div className="font-black text-center text-gray-400 py-4 text-2xl">
            Bank Details
          </div>
          <div></div>
          <form>
            <div className="py-5">
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    Bank Name
                  </label>
                  <div class="relative">
                    <select
                      class="block appearance-none w-full bg-white border border-gray-700 text-gray-700 py-5 px-4 pr-8 rounded-2xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-state"
                      //onChange={handleChange}
                    >
                      {/* <option disabled selected>
                        Select Bank
                      </option> */}
                      {bankDetail?.map((data, i) => (
                        <option selected value={JSON.stringify(data)}>
                          {data?.bank_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div class="flex   flex-wrap -mx-4 mb-2">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    Account Number
                  </label>

                  <div class="relative">
                    <select
                      class="block appearance-none w-full bg-white border border-gray-700 text-gray-700 py-5 px-4 pr-8 rounded-2xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-state"
                      //onChange={handleChange}
                    >
                      {/* <option disabled selected>
                        Select Bank
                      </option> */}
                      {bankDetail?.map((data, i) => (
                        <option selected value={JSON.stringify(data)}>
                          {data?.bank_account_number}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div class="flex   flex-wrap -mx-4 mb-2">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    Account Name
                  </label>

                  <div class="relative">
                    <select
                      class="block appearance-none w-full bg-white border border-gray-700 text-gray-700 py-5 px-4 pr-8 rounded-2xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-state"
                      //onChange={handleChange}
                    >
                      {/* <option disabled selected>
                        Select Bank
                      </option> */}
                      {bankDetail?.map((data, i) => (
                        <option selected value={JSON.stringify(data)}>
                          {data?.bank_account_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {/* <div class="flex   flex-wrap -mx-4 mb-2">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    Bank Name
                  </label>

                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-email"
                    type="text"
                    placeholder="Bank Name"
                    required
                    {...register("bank_name")}
                  />
                </div>
              </div> */}
            </div>
            {/* <div className="text-center w-full">
              <button className={`${loading} btn  lg:w-1/2 w-full`}>
                Update
              </button>
            </div> */}
          </form>

          {/* <div className="modal-action">
            <label htmlFor="my-modal-5" className="btn">
              Yay!
            </label>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default ViewBankDetails;
