import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function WithdrawModal({ walletBalance, getWalletBalance, setShowModal }) {
  const [showPassword, setShowPassword] = useState(false);
  const [bankData, setBankData] = useState([]);

  const [loading, setLoading] = useState();
  const [bankId, setBankId] = useState();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [showConfirm, setShowConfirm] = useState(false);

  // const getBankData = () => {
  //   const token = localStorage.getItem("giftToken");

  //   var config = {
  //     method: "get",
  //     url: `${process.env.REACT_APP_API}api/v1/bank_list`,
  //     headers: { Authorization: `Bearer ${token}` },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log(response.data?.data[0].name);
  //       setBankData(response.data?.data);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };
  const getBankDetails = (data) => {
    setLoading("loading");
    const token = localStorage.getItem("giftToken");
    // const PAYLOAD = {
    //   ...data,
    //   bank_code: bankId,
    // };
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}api/v1/list_customers_bank`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        setLoading("");
        setBankData(response.data?.data?.data);
        console.log(response.data);
      })
      .catch(function (error) {
        if (error?.response?.data?.error) {
          setLoading("");
          toast.error(error?.response?.data?.error[0], {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        } else {
          setLoading("");
          toast.error(error?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  useEffect(() => {
    getBankDetails();
  }, []);

  const handleChange = (e) => {
    setBankId(e.target.value);
  };
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const onSubmit = (data) => {
    setLoading("loading");
    const token = localStorage.getItem("giftToken");
    const PAYLOAD = {
      ...data,
      withdrawal_processing_id: bankId,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API}api/v1/withdraw`,
      headers: { Authorization: `Bearer ${token}` },
      data: { ...PAYLOAD },
    };

    axios(config)
      .then(function (response) {
        setLoading("");
        getWalletBalance();
        setShowModal(false);
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.error) {
          setLoading("");
          toast.error(error?.response?.data?.error[0], {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        } else {
          setLoading("");
          toast.error(error?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  return (
    <>
      {/* <label htmlFor="my-modal-5" className="btn modal-button">open modal</label> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <input type="checkbox" id="my-modal-1" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box w-11/12 max-w-[662px] h-[650px]">
          <label for="my-modal-1" class="   absolute left-2 top-2">
            <img src="/arrow-right.svg" className="h-8 w-8 font-bold" />
          </label>
          <div className="flex justify-between items-center">
            <div className="font-black text-center text-gray-400 py-4 text-lg md:text-2xl">
              Withdraw Fund
            </div>

            <div className="font-black text-center text-gray-400 py-4 text-lg md:text-2xl">
              ₦{numberWithCommas(walletBalance || 0)}
            </div>
          </div>
          {!bankData?.length && (
            <div>
              <div className="alert alert-warning shadow-lg">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  <span>
                    Please, kindly go to your profile to update your bank
                    details
                  </span>
                </div>
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="py-5">
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  <div class="relative">
                    <select
                      class="block appearance-none w-full bg-white border border-gray-700 text-gray-700 py-5 px-4 pr-8 rounded-2xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-state"
                      onChange={handleChange}
                    >
                      <option disabled selected>
                        Select Bank
                      </option>
                      {bankData?.map((data, i) => (
                        <option value={data?.id}>{data?.bank_name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div class="flex   flex-wrap -mx-4 mb-2">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  <div class="relative">
                    <select
                      class="block appearance-none w-full bg-white border border-gray-700 text-gray-700 py-5 px-4 pr-8 rounded-2xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-state"
                      //onChange={handleChange}
                    >
                      <option disabled selected>
                        Select Account Number
                      </option>
                      {bankData?.map((data, i) => (
                        <option value={data?.bank_account_number}>
                          {data?.bank_account_number}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div class="flex   flex-wrap -mx-4 mb-2">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  <div class="relative">
                    <select
                      class="block appearance-none w-full bg-white border border-gray-700 text-gray-700 py-5 px-4 pr-8 rounded-2xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-state"
                      //onChange={handleChange}
                    >
                      <option disabled selected>
                        Select Bank Name
                      </option>
                      {bankData?.map((data, i) => (
                        <option value={data?.bank_account_name}>
                          {data?.bank_account_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div class="flex   flex-wrap -mx-4 mb-2">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  {/* <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    Amount
                  </label> */}

                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-email"
                    type="text"
                    placeholder="Amount"
                    required
                    {...register("withdrawal_amount")}
                  />
                </div>
              </div>
              <div className="flex   flex-wrap -mx-4 mb-2">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  {/* <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    PIN
                  </label> */}
                  <img
                    src="/eye-icon.svg"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-5 top-[40%] w-8 h-8 transform -translate-y-1/2"
                  />

                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-email"
                    type={!showPassword ? "password" : "text"}
                    placeholder="Enter Pin"
                    required
                    {...register("withdrawal_txpin")}
                  />
                  <div className="absolute text-right"></div>
                </div>
              </div>
            </div>
            <div className="text-center w-full">
              <button className={`${loading} btn  lg:w-1/2 w-full`}>
                Withdraw
              </button>
            </div>
          </form>

          {/* <div className="modal-action">
            <label htmlFor="my-modal-5" className="btn">
              Yay!
            </label>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default WithdrawModal;
