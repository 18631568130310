import React from "react";
import Basic from "../components/profile/Basic";
import ImageSetting from "../components/profile/ImageSetting";
import Security from "../components/profile/Security";
import Verify from "../components/profile/Verify";

function Profiile() {
  return (
    <div className="md:pt-40 pt-20 max-w-6xl mx-auto">
      <div className="md:py-10 py-8 px-4">
        <ImageSetting />
      </div>
      <div className="grid md:grid-cols-3  grid-cols-1 md:gap-4 gap-3  lg:px-4 px-4">
        <div>
          <Basic />
        </div>
        <div>
          <Security />
        </div>
        <div>
          <Verify />
        </div>
      </div>
    </div>
  );
}

export default Profiile;
