import React from "react";

function About() {
  return (
    <div className="max-w-6xl mx-auto ">
      <div className="uppercase font-extrabold text-center py-7  text-3xl text-[#000000] pb-14 hidden lg:block">
        About Us
      </div>
      <div className=" items-stretch flex items-center justify-center">
        <div className="flex flex-col-reverse  lg:flex-row -flex-wrap  items-center justify-between">
          <div className="pt-4 md:pb-0">
            <img src="/man.svg" />
          </div>
          <div className="w-full lg:w-1/2 lg:pl-12">
            <h1 className=" font-bold text-base lg:text-2xl md:text-3xl  lg:py-6 py-4 text-[#474747] text-center lg:text-start">
              We Have Been Around
            </h1>
            <div className="md:text-lg text-xs text-center lg:text-start text-[#474747]">
              Ideal Gift cards To Naira Exchange is unarguably extraordinary compared to other gift cards trading
              platforms in Nigeria. We have been in presence since 2021 trading giftcards satisfactorily and offering
              profitable rates to clients in Nigeria,and beyond.
              <p className="md:pt-10 py-4">We sell and buy all types of gift cards. Just name it.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
