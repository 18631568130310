import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import "../components/header/css/ForgetPassword.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Forgetpassword() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState();

  const navigate = useNavigate();
  const onSubmit = (data) => {
    setLoading("loading");
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API}api/auth/forgot_password`,
      headers: {},
      data: { ...data },
    };

    axios(config)
      .then(function (response) {
        setLoading("");
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        localStorage.setItem("reset-email", data.email);
        navigate("/reset-password");
      })
      .catch(function (error) {
        if (error?.response?.data?.error) {
          setLoading("");
          toast.error(error?.response?.data?.error[0], {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        } else {
          setLoading("");
          toast.error(error?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  return (
    <div className="bg-[#F8F8F8]">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="md:px-8 md:pt-40 bg-white -md:w-1/2 flex items-center  justify-center">
        <div className="pt-24 md:pt-16  md:border md:border-[#C4C4C4] md:rounded-2xl md:px-16">
          <div className="text-center font-bold text-[#034B5E] text-xl">
            Forgot Password
          </div>
          <div className="text-center text-xs">
            Please select option to send link reset password
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-2  pt-8 -md:h-screen">
              <div className="rounded-xl bg-white md:px-5   py-6 border border-[#C4C4C4]">
                <div className="flex md:px-11 md:py-8 px-2 items-center">
                  <div className="rounded-full px-5 py-5 bg-[#298097]">
                    <img src="/msg-icon.svg" />
                  </div>
                  <div className="pl-4">
                    <div className="text-[#034B5E] font-bold text-xl">
                      {" "}
                      Reset via Email
                    </div>
                    <div className="text-xs">
                      We will send a link to reset your password
                    </div>
                  </div>
                </div>
                <div class="flex flex-wrap px-2 pt-5 -mx-3 mb-6">
                  <div class="w-full px-3">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-name"
                    >
                      Email Address <span className="text-red-600">*</span>
                    </label>
                    <input
                      class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                      id="grid-name"
                      type="text"
                      placeholder="Email Address"
                      required
                      {...register("email")}
                    />
                  </div>
                </div>
              </div>
              <div className="text-center    pt-14 w-full">
                <button
                  className={`${loading} btn btn-warning lg:w-full w-full`}
                >
                  Send Token Reset Password
                </button>
              </div>
              <Link to="/login">
                <div className="inline-flex pb-9 pt-3 items-center">
                  <div>
                    <img src="/arrow.svg" />
                  </div>

                  <div className="pl-1  cursor-pointer">Back to sign in</div>
                </div>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Forgetpassword;
