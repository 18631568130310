import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Login() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const watchPolicy = watch("policy", false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const onSubmit = (data) => {
    setLoading("loading");
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API}api/auth/login`,
      headers: {},
      data: { ...data },
    };

    axios(config)
      .then(function (response) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading("");
        localStorage.setItem("giftToken", response?.data?.data?.access_token);
        localStorage.removeItem("signupResponse");
        //console.log(response?.data?.data?.access_token);
        //return <Navigate to="/dashboard/overview" />;
        navigate("/dashboard/overview");
      })
      .catch(function (error) {
        if (error?.response?.data?.error) {
          setLoading("");
          toast.error(error?.response?.data?.error[0], {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        } else {
          setLoading("");
          toast.error(error?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  const signupResponse = localStorage.getItem("signupResponse");
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex items-center pt-12 md:pt-0 -justify-center ">
        <div className="lg:w-1/2 lg:px-7 w-full">
          <div className="font-extrabold text-[#034B5E] py-12 px-8 text-2xl md:text-3xl">
            Hey, Welcome Back!
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit)} class="w-full px-4">
              {signupResponse && (
                <div className="py-4 px-3">
                  <div className="alert alert-success shadow-lg">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        className="stroke-current flex-shrink-0 w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                      <span className="font-black text-sm">
                        Your Account has been created successfully
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-name"
                  >
                    Email Address <span className="text-red-600">*</span>
                  </label>
                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-name"
                    type="text"
                    placeholder="Email Address"
                    required
                    {...register("email")}
                  />
                  {/* <p class="text-red-600 text-xs italic">
                  Make it as long and as crazy as you'd like
                </p> */}
                </div>
              </div>

              <div class="flex   flex-wrap -mx-4 mb-2">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    Password
                  </label>
                  <img
                    src="/eye-icon.svg"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-5 top-[64%] w-8 h-8 transform -translate-y-1/2"
                  />
                  <Link to="/forget-password">
                    <div className="cursor-pointer text-[#F8AD15] text-right">
                      Forgot Password?
                    </div>
                  </Link>

                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-email"
                    type={!showPassword ? "password" : "text"}
                    placeholder="password"
                    required
                    {...register("password")}
                  />
                  <div className="absolute text-right"></div>
                </div>
              </div>

              <div className="text-center w-full">
                <button
                  className={`${loading} btn btn-warning lg:w-1/2 w-full`}
                >
                  Sign In
                </button>
              </div>
              <Link to="/signup">
                <div className="py-5 text-left lg:text-center">
                  You dont Have an Account?{" "}
                  <span className="text-yellow-300 font-bold">Sign Up</span>
                </div>
              </Link>
            </form>
          </div>
        </div>

        <div className="hidden md:block w-1/2 h-screen bg-gradient-to-r from-[#267E92] to-[#267E92] ">
          <Link to="/">
            <div className="py-11 px-6 ">
              <img
                src="/logo.svg"
                className="py-4 px-6 rounded-3xl bg-[#034B5E]"
              />
            </div>
          </Link>

          <div className=" text-3xl md:text-2xl lg:text-3xl xl:text-5xl py-3 text-white font-bold px-4">
            Gain <span className="text-[#F8AD15] font-bold">Secure Access</span>{" "}
            to your account
          </div>
          <div className="flex justify-center py-3 items-center">
            <img src="/bg-4.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
