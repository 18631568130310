import React from "react";
import Navbar from "../components/header/Navbar";
import GiftCardForm from "../components/dashboard/GiftCardForm";
import Footer from "../components/footer/Footer";

function Rate() {
  return (
    <>
      <div class="relative h-[317px]  w-full text-center bg-cover bg-center bg-[#034B5E]">
        <div className="absolute w-full lg:pt-3">
          <Navbar />
        </div>

        <main class="  pt-40 ">
          <div className="text-center font-black text-white text-3xl lg:text-4xl">
            We offer the best gift card rates.
          </div>
        </main>
      </div>
      <div className="max-w-4xl mx-auto ">
        <GiftCardForm />
      </div>
      <Footer />
    </>
  );
}

export default Rate;
