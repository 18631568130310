import React from "react";
import { Outlet, useLocation } from "react-router-dom";

export default function MainPage() {
  const location = useLocation();
  const { pathname } = location;
  return (
    <div className="pt-24 md:pt-40 lg:36 ">
      {/* <div className="flex items-center justify-center">
        <div className="text-lg text-gray-400 lg:text-2xl font-black">
          Select Gift Card
        </div>
        <div className="flex justify-evenly pl-3">
          <div className="">
            <div
              className={`h-3 w-3 rounded-full ${
                pathname.includes("list-card") ||
                pathname.includes("select-currency") ||
                pathname.includes("denomination") ||
                pathname.includes("select-card")
                  ? "bg-[#F8AD15]"
                  : "bg-[#D9D9D9]"
              } `}
            ></div>
          </div>
          <div className="pl-2">
            <div
              className={`h-3 w-3 rounded-full ${
                pathname.includes("select-currency") ||
                pathname.includes("denomination") ||
                pathname.includes("select-card")
                  ? "bg-[#F8AD15]"
                  : "bg-[#D9D9D9]"
              } `}
            ></div>
          </div>
          <div className="pl-2">
            <div
              className={`h-3 w-3 rounded-full ${
                pathname.includes("denomination") ||
                pathname.includes("select-card")
                  ? "bg-[#F8AD15]"
                  : "bg-[#D9D9D9]"
              } `}
            ></div>
          </div>
          <div className="pl-2">
            <div
              className={`h-3 w-3 rounded-full ${
                pathname.includes("denomination")
                  ? "bg-[#F8AD15]"
                  : "bg-[#D9D9D9]"
              } `}
            ></div>
          </div>
        </div>
      </div> */}
      <Outlet />
    </div>
  );
}
