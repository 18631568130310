import React from "react";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import { FloatingWhatsApp } from "react-floating-whatsapp";

function Footer() {
  return (
    <>
      <FloatingWhatsApp
        accountName="IGCEX Gift Card"
        allowClickAway={true}
        notification={true}
        notificationSound={true}
        darkMode={true}
        phoneNumber="+2348086961452"
      />
      <footer className="h-full md:h-[400px] px-6 md:px-4  lg:px-28 bg-[#023A49] text-neutral-content">
        {/* <div className="fixed w-12 lg:w-16  bottom-0 right-6 z-10 lg:bottom-10 lg:right-10">
          <img src="/whatsapp.svg" className="" />
        </div> */}

        <div className="flex flex-wrap md:pt-20  py-10 md:py-0 max-w-6xl mx-auto">
          <div className="lg:w-[40%] w-full text-[18px] text-[#fff] mx-auto">
            <p className="lg:w-[80%] w-full text-sm md:text-[18px] font-normal ">
              A Verified Platform To Sell Gift Cards In Nigeria Securely At High
              Rates.
            </p>
            <div className="flex pt-8 pb-10">
              {" "}
              <a href="#" className="pr-2">
                <img src="/linkedIn.svg" />
              </a>
              <a href="#">
                <img src="/twitter.svg" />
              </a>
            </div>
          </div>
          {/* <div className="flex justify-between"> */}
          <div className="lg:w-[30%] w-[60%] mx-auto">
            <h1 className="font-extrabold md:text-[18px]  text-[16px] text-[#fff] pb-3">
              Information
            </h1>
            <div className=" py-3 transform w-full md:w-[50%] hover:text-[#1397CF] transition duration-400 hover:scale-125 ">
              <a href="/policy" className="md:text-[16px] text-[14px]">
                Privacy Policy
              </a>
            </div>
            <div className=" py-3 transform   w-full md:w-[50%] hover:text-[#1397CF] transition duration-400 hover:scale-125 ">
              <a href="/policy" className="md:text-[16px] text-[14px]">
                Terms and Conditions
              </a>
            </div>
            <div className=" py-3 transform  w-[30%] hover:text-[#1397CF] transition duration-500 hover:scale-125 ">
              <a href="/rate" className="md:text-[16px] text-[14px]">
                Rates
              </a>
            </div>
          </div>
          <div className="lg:w-[30%] w-[40%] mx-auto">
            <h1 className="font-extrabold md:text-[18px]  text-[16px] text-[#fff] pb-3">
              About Us
            </h1>
            <div className=" py-3 transform   w-full md:w-[50%] hover:text-[#1397CF] transition duration-400 hover:scale-125 ">
              <a href="#" className="md:text-[16px] text-[14px]">
                About
              </a>
            </div>
            <div className=" py-3 transform  w-[20%] hover:text-[#1397CF] transition duration-400 hover:scale-125 ">
              <a href="#" className="md:text-[16px] text-[14px]">
                Blog
              </a>
            </div>
            <div className=" py-3 transform   w-full md:w-[50%] hover:text-[#1397CF] transition duration-400 hover:scale-125 ">
              <a href="/contact-us" className="md:text-[16px] text-[14px] ">
                Contact Us
              </a>
            </div>
            {/* </div> */}
          </div>
        </div>
      </footer>
      <footer className="footer flex justify-center items-center p-4 bg-[#022832] text-neutral-content">
        <div className="items-center lg:text-lg text-center">
          <p className="text-white md:text-[16px] text-[12px]">
            Copyright © 2020 IGCX Network. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
}
export default Footer;
