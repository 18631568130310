import React, { useEffect } from "react";

import { useNavigate, Outlet, Navigate } from "react-router-dom";

export default function ProtectedRoute({ redirectPath = "/login", children }) {
  const navigate = useNavigate();
  const user = localStorage.getItem("giftToken");
  if (!user) {
    navigate(redirectPath);
  }

  return children;
}
