import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Forgetpassword from "./pages/Forgetpassword";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import TransactionDetail from "./components/dashboard/TransactionDetail";
import DashboardOverview from "./components/dashboard/DashboardOverview";
import Trade from "./components/dashboard/Trade";
import TransactionHistory from "./components/dashboard/TransactionHistory";
import ListCards from "./components/trading/ListCards";
import MainPage from "./components/trading/MainPage";
import SelectCurrency from "./components/trading/SelectCurrency";
import SelectCard from "./components/trading/SelectCard";
import SelectDomination from "./components/trading/SelectDomination";
import Rate from "./pages/Rate";
import ContactUs from "./pages/ContactUs";
import Profiile from "./pages/Profiile";
import Policy from "./pages/Policy";
import ProtectedRoute from "./components/ProtectedRoutes";
import StartTrade from "./components/trading/StartTrade";
import VerifyAccount from "./pages/VerifyAcoount";
import NotificationPage from "./pages/NotificationPage";
function RoutesFile() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="login" element={<Login />} />
      <Route path="verify" element={<VerifyAccount />} />
      <Route path="forget-password" element={<Forgetpassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="contact-us" element={<ContactUs />} />
      <Route path="rate" element={<Rate />} />
      <Route path="policy" element={<Policy />} />

      <Route path="dashboard" element={<Dashboard />}>
        <Route
          index
          element={
            <ProtectedRoute>
              <DashboardOverview />
            </ProtectedRoute>
          }
        />
        <Route
          path="overview"
          element={
            <ProtectedRoute>
              <DashboardOverview />
            </ProtectedRoute>
          }
        />
        <Route
          path="profile"
          element={
            <ProtectedRoute>
              <Profiile />
            </ProtectedRoute>
          }
        />
        <Route
          path="trade"
          element={
            <ProtectedRoute>
              <Trade />
            </ProtectedRoute>
          }
        />
        <Route
          path="history"
          element={
            <ProtectedRoute>
              <TransactionHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="detail"
          element={
            <ProtectedRoute>
              <TransactionDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="sell"
          element={
            <ProtectedRoute>
              <MainPage />
            </ProtectedRoute>
          }
        >
          <Route
            path="list-card"
            element={
              <ProtectedRoute>
                <ListCards />
              </ProtectedRoute>
            }
          />
          <Route
            path="start-trading/:id"
            element={
              <ProtectedRoute>
                <StartTrade />
              </ProtectedRoute>
            }
          />

          {/* <Route path="select-currency" element={<SelectCurrency />} />

          <Route path="select-card" element={<SelectCard />} />
          <Route path="denomination" element={<SelectDomination />} /> */}
        </Route>
        <Route
          path="notification"
          element={
            <ProtectedRoute>
              <NotificationPage />
            </ProtectedRoute>
          }
        />
      </Route>
      {/* <Route exact path="/detail" element={<TransactionDetail />} /> */}
    </Routes>
  );
}

export default RoutesFile;
