import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <div
      class="relative h-full  w-full text-center bg-cover bg-center"
      style={{
        backgroundImage: `url(/bg.svg)`,
      }}
    >
      <div className="absolute w-full lg:pt-3">
        <Navbar />
      </div>

      <main class=" max-w-6xl mx-auto ">
        <div className="flex flex-col lg:flex-row items-start lg:pt-24 justify-evenly ">
          <div className="w-full h-full xl:w-[80%] lg:w-[50%]  mx-auto pt-7  lg:pt-36 md:pt-32 mx-auto flex flex-col  lg:justify-center">
            {/* <div className="w-full h-full  lg:w-3/5 xl:1/2   mx-auto pt-7  lg:pt-36 md:pt-32 mx-auto flex flex-col  lg:justify-center"> */}
            <div className=" px-6  md:px-8 lg:px-4 xl:px-8  pt-10 lg:pt-30 text-white  text-left  text-2xl md:text-4xl lg:text-6xl">
              <div className="w-full font-extrabold pt-14 md:pt-0 ">
                <p className="text-[2rem] xl:text-[3.75rem] sm:text-[3rem] pb-1 sm:pb-2 md:pb-1 xl:pb-1">
                  SELL GIFT CARDS
                </p>
                <p>
                  <p className="text-[2rem]  xl:text-[3.75rem] sm:text-[3rem] ">
                    <span className="text-white">AT</span>{" "}
                    <span className="text-yellow-300">BEST RATES</span>
                  </p>
                </p>

                <div className=" w-[70%] md:w-full text-left text-white font-normal  md:py-2 py-1">
                  <p className="text-sm md:text-2xl lg:text-lg">
                    A more convenient way to sell unused Giftcards with ease.
                  </p>
                </div>
                <div
                  className="w-fit mt-4 rounded-full  border border-white text-base  font-regular   text-black bg-[#FFFFFF] hover:bg-yellow-300 focus:outline-none focus:border-yellow-300 focus:shadow-outline-yellow transition duration-150 ease-in-out 
                "
                >
                  <Link to="/dashboard/overview">
                    <button className="md:text-base text-sm text-center py-3 px-5">
                      Get Started
                    </button>
                  </Link>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="lg:pt-16 pt-0 ">
            <img src="/hero-img.svg" className="w-[100%] mx-auto" />
          </div>

          {/* <div className="flex w-full lg:w-1/2 mx-auto lg:h-[700px] md:h-[500px] sm:h-[400px] h-[300px]">
            <div
              style={{
                backgroundImage: `url("/hero-img.svg")`,
              }}
              className="grow bg-no-repeat bg-contain min-h-full bg-contain bg-bottom lg:bg-right bg-no-repeat -mx-[0rem] lg:-ml-[10rem] xl:-mx-[8rem] xl:mt-24"
            ></div>
          </div> */}
        </div>
      </main>
    </div>
  );
}

export default Hero;
