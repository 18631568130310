import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
function NotificationPage() {
  const [trade, setTrade] = useState([]);
  const Per_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState();
  const [notData, setNotData] = useState([]);
  const getActiveTrade = () => {
    const token = localStorage.getItem("giftToken");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}api/v1/notifications?page=${currentPage}`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        setNotData(response.data?.data);
        setTotal(response?.data?.data?.total);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getActiveTrade();
  }, [currentPage]);
  const pageCount = Math.ceil(total / Per_PAGE);
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }

  return (
    <div className=" pt-32 md:pt-36 md:px-20  max-w-7xl mx-auto px-3">
      <div className="bg-white md:px-4 ">
        <div className="font-black text-xl text-[#298097] md:px-4 py-4 ">
          Notifications
        </div>

        {notData?.map((data, i) => (
          <div class=" grid place-items-center my-8">
            <div
              key={i}
              class="mt-2 px-6 py-4 bg-white rounded-lg shadow w-full"
            >
              <div class=" inline-flex items-center justify-between w-full">
                <div class="inline-flex items-center">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/893/893257.png"
                    alt="Messages Icon"
                    class="w-6 h-6 mr-3"
                  />
                  <h3 class="font-bold text-base text-gray-800">
                    Notification
                  </h3>
                </div>
                <p class="text-xs text-gray-500">
                  {new Date(data?.updated_at).toLocaleDateString()}
                </p>
              </div>
              <p class="mt-1 text-sm">{data?.notification}</p>
            </div>
          </div>
        ))}
      </div>
      {/* <div className="pt-5">
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName="pagination justify-center"
          previousClassName=""
          nextLinkClassName=""
          disabledClassName=""
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div> */}
    </div>
  );
}

export default NotificationPage;
