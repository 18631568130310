import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MultiImageInput from "react-multiple-image-input";
import ImageUploading from "react-images-uploading";
function StartTrade() {
  const { id } = useParams();
  const [images, setImages] = useState([]);
  const [image, setImage] = useState([]);
  const hiddenFileInput = React.useRef(null);

  const [cardsCat, setCardsCat] = useState([]);
  const [selectCat, setSelectCat] = useState();
  const [cardAmount, setCardAmount] = useState();
  const [cardTypes, setCardTypes] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [totalAmount, setTotalAmount] = useState();
  const [cardTypeParsed, setCardTypeParsed] = useState();
  const [ecodeValue, setEcodeValue] = useState();
  const [eCode, setEcode] = useState();
  const [loading, setLoading] = useState();
  const [receipt, setReceipt] = useState();
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  const getCardCat = () => {
    const token = localStorage.getItem("giftToken");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}api/v1/get-giftcards-details/${id}?paginate=false`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        setCardsCat(response.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getCardType = () => {
    const token = localStorage.getItem("giftToken");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}api/v1/get-card-types`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        setCardTypes(response.data?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleGiftCard = (e) => {
    getCardCat(e.target.value);
  };
  const handleCatChange = (e) => {
    const parsedData = JSON.parse(e.target.value);
    setSelectCat(parsedData);
  };
  const handleAmountChange = (e) => {
    setTotalAmount(+selectCat.gct_rate * +e.target.value);
    setCardAmount(e.target.value);
  };
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    //const size = file.size / 1024;
    // SetImageUploadError("");
    const reader = new FileReader();
    reader.onloadend = function () {
      setImage({ [e.target.name]: reader.result });

      //setPreview({ ...preview, [e.target.name]: reader.result });
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };

  const handleCardChange = (e) => {
    const parsedData = JSON.parse(e.target.value);

    if (parsedData?.gfc_name == "E-Code") {
      setEcode("ecode");
      setShowUpload(true);
    } else {
      setEcode("multiple_images");
      //setReceipt("receipt_image");
      setShowUpload(false);
    }
    setCardTypeParsed(parsedData);
  };

  const processOrder = () => {
    setLoading("loading");
    const token = localStorage.getItem("giftToken");

    const PAYLOAD = {
      category_id: selectCat?.gct_id,
      category_type_id: cardTypeParsed?.gfc_id,
      amount: cardAmount,

      [eCode]:
        eCode == "ecode"
          ? ecodeValue
          : [images[0], images[1], images[2], images[3]],

      // ecode: "",
      // giftcard_image: image.image,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API}api/v1/process-order`,
      data: { ...PAYLOAD },
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading();
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading();
      });
  };
  const handleEcodeChange = (e) => {
    setEcodeValue(e.target.value);
  };
  useEffect(() => {
    getCardCat();
    getCardType();
  }, []);
  console.log(cardsCat);
  return (
    <div className="max-w-7xl mx-auto">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="grid md:grid-cols-3  grid-cols-1 md:gap-4 gap-5  lg:px-0 px-4">
        <div className="col-span-1 bg-white px-3">
          <Link to="/dashboard/sell/list-card">
            <div className="text-center  py-5">
              <button class="btn bg-[#2F2E41] btn-lg w-full  md:w-96   text-white text-xl -btn-warning">
                <img src="/arrow-left.svg" />
                Go Back
              </button>
            </div>
          </Link>
          <div className="text-center flex justify-center">
            {console.log(cardsCat[0]?.gift_card_name?.gc_image)}
            <img
              src={cardsCat[0]?.gift_card_name?.gc_image.replace(
                "storage",
                "https://live-office.igcex.ng/storage/app/public"
              )}
            />
          </div>
        </div>
        <div className="col-span-2 bg-white rounded-xl px-5">
          <div className="text-[#298097] text-xl py-3 font-black">
            Trade Gift Card
          </div>
          <form>
            <div>
              <select
                onChange={handleCatChange}
                id="countries"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option selected disabled>
                  Card Sub Category
                </option>
                {cardsCat.map((data, i) => (
                  <option key={i} value={JSON.stringify(data)}>
                    {data?.gct_name}{" "}
                    {`(${data?.gct_min_range} 
                      - ${data?.gct_max_range})`}
                  </option>
                ))}
              </select>
            </div>
            <div className="py-5">
              <select
                id="countries"
                onChange={handleCardChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option selected disabled>
                  Select Card
                </option>
                {cardTypes?.map((data, i) => (
                  <option key={i} value={JSON.stringify(data)}>
                    {data?.gfc_name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <input
                class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                id="grid-email"
                type="text"
                placeholder="Card Amount"
                required
                onChange={handleAmountChange}
              />
            </div>
            <div className="text-gray-400 font-black py-5">
              Rate -{" "}
              <span className="font-black text-black">
                {selectCat?.gct_rate}
              </span>{" "}
              {selectCat?.gift_card_currency?.gcc_iso}
              <div>
                Tips: You can upload multiple cards at the once to benefit
                higher rates
              </div>
            </div>
            <div className="text-gray-400 font-black text-center text-lg">
              You get paid
            </div>
            <div className="text-black font-black text-center text-2xl">
              ₦{numberWithCommas(totalAmount || 0)}
            </div>
            <div className="text-gray-400 font-black ">
              <ol className="list-decimal">
                <li>
                  {" "}
                  It takes minute(s) to process the order Depending on your card
                  type
                </li>
                <li>Don't send electronic display Cards as Physical card</li>
                <li>
                  Please Don't multi share your Cards to multiple platforms at
                  the same time to avoid loses and Distrust
                </li>
                <li>
                  Please confirm card denomination carefully before trading. You
                  can also send receipt if necessary
                </li>
              </ol>
            </div>
            {!showUpload ? (
              <div>
                <div className="pt-3 text-gray-500">
                  Please Upload Gift Card Image First
                </div>
                <MultiImageInput
                  images={images}
                  setImages={setImages}
                  allowCrop={false}
                  cropConfig={{ minWidth: 10 }}
                  max={4}
                  theme={{
                    background: "#ffffff",
                    outlineColor: "#808080",
                    textColor: "rgba(255,255,255,0.6)",
                    buttonColor: "#ff0e1f",
                    modalColor: "#ffffff",
                  }}
                />
              </div>
            ) : (
              <div className="py-4 flex justify-center">
                <input
                  class="-appearance-none block placeholder:text-center   bg-white text-gray-700 border border-gray-500 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                  id="grid-email"
                  type="text"
                  placeholder="Enter Code"
                  required
                  onChange={handleEcodeChange}
                />
              </div>
            )}

            {/* {!showUpload ? (
              <label
                className="flex py-2 justify-center"
                title="click to select a picture"
              >
                <input
                  accept="image/*"
                  className="hidden"
                  id="banner"
                  type="file"
                  name="image"
                  onChange={handleFileChange}
                  ref={hiddenFileInput}
                  visibility="hidden"
                  multiple
                />
                <div className="flex flex-col justify-center items-start">
                  {image?.length ? (
                    <div className="pt-4">
                      <div>
                        <img
                          className="-object-contain -mt-8 p-5 w-56"
                          src={image ? image.image : ""}
                          alt=""
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-center">
                      <button
                        onClick={handleClick}
                        type="button"
                        class="bg-[#F8AD15]  text-xl  text-white font-bold py-4 px-10 rounded"
                      >
                        Upload Image
                      </button>
                    </div>
                  )}
                </div>
              </label>
            ) : (
              <>
                <div className="py-4 flex justify-center">
                  <input
                    class="-appearance-none block placeholder:text-center   bg-white text-gray-700 border border-gray-500 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-email"
                    type="text"
                    placeholder="Enter Code"
                    required
                    onChange={handleEcodeChange}
                  />
                </div>
              </>
            )} */}
            <div className="-px-28 pb-10">
              <div className="flex py-4  justify-center">
                <button
                  onClick={processOrder}
                  disabled={images[0] || ecodeValue ? false : true}
                  type="button"
                  class={`bg-[#F8AD15] ${loading} btn btn-warning   text-xl  text-white font-bold py-4 px-16 rounded`}
                >
                  Submit Trade
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default StartTrade;
