import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";

export default function TransactionDetail() {
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <>
      <div className="pt-36 max-w-7xl mx-auto md:pt-44">
        <div className="flex md:flex-row flex-col items-stretch md:space-x-11 gap-y-8 justify-center">
          <div className="md:px-10  xl:px-40 px-4 w-full  md:w-2/3 ">
            <div className="bg-white rounded-xl">
              <div className="text-center py-2 text-3xl font-black text-gray-400">
                Transactions
              </div>
              <div className="text-center py-2 font-bold text-gray-400">
                Full information about trade
              </div>
              <div className="flex items-center md:justify-evenly justify-between px-3 py-3">
                <div>
                  <div className="font-black text-gray-400 text-sm md:text-lg">
                    CARD TYPE
                  </div>
                  <div className="font-bold ">Itunes</div>
                </div>
                <div>
                  <div className="font-black text-gray-400 text-sm md:text-lg">
                    QUANTITY
                  </div>
                  <div className="font-bold ">I Card</div>
                </div>
              </div>
              <div className="flex items-center md:justify-evenly justify-between px-3 py-3">
                <div>
                  <div className="font-black text-gray-400 text-sm md:text-lg">
                    DENOMINATION
                  </div>
                  <div className="font-bold ">50 USD</div>
                </div>
                <div>
                  <div className="font-black text-gray-400 text-sm md:text-lg">
                    RECEIPT TYPE
                  </div>
                  <div className="font-bold ">Physical Card</div>
                </div>
              </div>

              <div className="flex items-center justify-center py-10">
                <div className="rounded-lg  -w-96  bg-gray-300 px-6 py-2 ">
                  <div className="text-base   px-10">Sep 17, 2022 · 21:27</div>
                </div>
              </div>
              <div className="flex items-center md:justify-evenly justify-between px-3 py-3">
                <div>
                  <div className="font-black text-gray-400 text-sm md:text-lg">
                    Offer
                  </div>
                  <div className="font-bold ">Status</div>
                </div>
                <div>
                  <div className="font-black text-gray-400 text-sm md:text-lg">
                    <div className="font-bold text-base ">
                      ₦{numberWithCommas(50000)}
                    </div>
                  </div>
                  <div className="font-semibold ">Processing</div>
                </div>
              </div>
              <div className="text-center py-5">
                <button class="btn bg-[#2F2E41] btn-lg w-1/2   text-white text-xl btn-warning">
                  View Trade
                </button>
              </div>
            </div>
          </div>

          <div className="md:w-1/3  w-full  lg:px-12 px-4">
            <div className="bg-white rounded-xl py-10">
              <div className="flex items-center justify-between px-2 lg:px-6 py-4">
                <div>
                  <div>
                    <img src="/mark-icon.svg" />
                  </div>
                </div>
                <div>
                  <div>ITunes Gift Card</div>
                </div>
              </div>
              <div className="flex items-center justify-between px-2 lg:px-6 py-4">
                <div>
                  <div>
                    <img src="/mark-icon.svg" />
                  </div>
                </div>
                <div>
                  <div>ITunes Gift Card</div>
                </div>
              </div>
              <div className="flex items-center justify-between px-2 lg:px-6 py-4">
                <div>
                  <div>
                    <img src="/mark-icon.svg" />
                  </div>
                </div>
                <div>
                  <div>ITunes Gift Card</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
