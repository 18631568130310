import React, { useState } from "react";
import ActiveTrade from "../components/dashboard/ActiveTrade";
import DashboardHeader from "../components/dashboard/DashboardHeader";
import GiftCardForm from "../components/dashboard/GiftCardForm";
import Trade from "../components/dashboard/Trade";
import TransactionHistory from "../components/dashboard/TransactionHistory";
import Wallet from "../components/dashboard/Wallet";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import TransactionDetail from "../components/dashboard/TransactionDetail";

function Dashboard() {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="relative bg-[#F8F8F8] -min-h-max min-h-screen">
      <div className="absolute ">
        <DashboardHeader
          pathname={pathname}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
      <Outlet />
      {/* {!pathname.includes("detail") && (
        <div>
          {activeTab == 0 && (
            <div className="flex px-4 md:space-x-6 md:pt-20 items-center md:justify-evenly flex-col md:flex-row">
              <div className="md:w-2/5 h-full">
                <div className="md:pt-20 pt-20 -w-full">
                  <Wallet />
                </div>
                <div className="pt-7">
                  <ActiveTrade />
                </div>
              </div>
              <div className="pt-7 h-full  md:w-3/5">
                <GiftCardForm />
              </div>
            </div>
          )}

          {activeTab === 1 && (
            <div className="pt-32 md:pt-36 md:px-20 lg:40 px-3">
              <Trade />
            </div>
          )}

          {activeTab === 2 && (
            <div className="pt-40 lg:px-60  px-3">
              <TransactionHistory />
            </div>
          )}
        </div>
      )} */}
    </div>
  );
}

export default Dashboard;
