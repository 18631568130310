import React,{useEffect} from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import About from "./components/about/About";
import Cards from "./components/cards/Cards";
import Hero from "./components/header/Hero";
import LandingPage from "./pages/LandingPage";
import RoutesFile from "./Routes";
import { lastIndexOf, substr } from "@7urtle/lambda";
import { IntercomProvider } from "react-use-intercom";

function App() {
  const getBasename = (path) => substr(lastIndexOf("/")(path))(0)(path);
useEffect(() => {
  window.Intercom('boot', { 
    app_id: 'YOUR_INTERCOM_APP_ID' 
  });
}, [ ])

  return (
    <IntercomProvider appId={""}>
       <div>
      <Router >
        <RoutesFile />
      </Router>
    </div>
    </IntercomProvider>
   
  );
}

export default App;
