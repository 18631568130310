import axios from "axios";
import React, { useState, useEffect } from "react";
import BasicModal from "./modals/BasicModal";
import PasswordModal from "./modals/PasswordModal";
import PhoneModal from "./modals/PhoneModal";
import PinModal from "./modals/PinModal";

function Basic() {
  const [profileData, setProfileData] = useState();
  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = () => {
    const token = localStorage.getItem("giftToken");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}api/v1/customer_profile`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data?.data);
        setProfileData(response.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div>
      <BasicModal />
      <PhoneModal />
      <PasswordModal />

      <div className="max-w-[432px] h-[530px] rounded-xl bg-white px-3">
        <div className="py-4 px-1 font-black text-2xl">
          Basic
          <div className="font-light text-sm">Account Basic Security</div>
        </div>
        <div className="flex py-5 justify-between items-center">
          <div>
            <div className="inline-flex items-center">
              <div>
                {" "}
                <img src="/side-icon.svg" />
              </div>
              <div className="text-lg pl-2 text-gray-400 font-bold">
                Email Address
                <div className="text-sm text-gray-400 font-light">
                  {profileData?.email}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex py-5 justify-between items-center">
          <div>
            <div className="inline-flex items-center">
              <div>
                {" "}
                <img src="/side-icon.svg" />
              </div>
              <div className="text-lg pl-2 text-gray-400 font-bold">
                Phone number
                <div className="text-sm text-gray-400 font-light">
                  {profileData?.phone_no}
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            <label
              htmlFor="my-modal-1"
              className="btn rounded-3xl  btn-sm text-sm btn-info bg-[#CEEDFF] modal-button"
            >
              Edit
            </label>
          </div> */}
        </div>
        <div className="flex py-5 justify-between items-center">
          <div>
            <div className="inline-flex items-center">
              <div>
                {" "}
                <img src="/side-icon.svg" />
              </div>
              <div className="text-lg pl-2 text-gray-400 font-bold">
                Password
                <div className="text-sm text-gray-400 font-light">
                  * * * * * * *
                </div>
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="my-modal-2"
              className="btn rounded-3xl  btn-sm text-sm btn-info bg-[#CEEDFF] modal-button"
            >
              Edit
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Basic;
