import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

function DashboardHeader({ activeTab, setActiveTab, pathname }) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigate = useNavigate();
  const [notData, setNotData] = useState([]);
  const handleToggle = () => {
    setToggleMenu(!toggleMenu);
  };
  const btn = document.querySelector("button.mobile-menu-button");
  const menu = document.querySelector(".mobile-menu");
  const btnClick = () => {
    setToggleMenu(!toggleMenu);
  };
  const logOut = () => {
    localStorage.removeItem("giftToken");
    navigate("/login");
  };
  const getNotification = () => {
    const token = localStorage.getItem("giftToken");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}api/v1/notifications`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        setNotData(response.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getNotification();
  }, []);
  return (
    <>
      <div className=" flex items-center justify-center pt-3 lg:pl-2 xl:pl-10">
        <div className="hidden rounded-lg  bg-white md:flex justify-evenly items-center lg:justify-center py-2">
          <Link to="/">
            <div>
              <img src="/logo-black.svg" />
            </div>
          </Link>

          <ul class="flex flex-wrap lg:pl-8 text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li class="mr-2">
              <Link to="/dashboard/overview">
                <div
                  className={`inline-block text-lg font-bold py-3 px-4  ${
                    pathname.includes("overview")
                      ? "bg-[#034B5E] text-[#F8AD15]"
                      : " bg-transparent"
                  } rounded-3xl active`}
                  aria-current="page"
                  onClick={(e) => {
                    setActiveTab(0);
                  }}
                >
                  Dashboard
                </div>
              </Link>
            </li>
            <li class="mr-2">
              <Link to="/dashboard/trade">
                <div
                  class={`inline-block text-lg font-bold py-3 px-4 ${
                    pathname.includes("trade")
                      ? "bg-[#034B5E] text-[#F8AD15]"
                      : " bg-transparent"
                  } rounded-3xl active`}
                  aria-current="page"
                  onClick={(e) => {
                    setActiveTab(1);
                  }}
                >
                  Gift Card Trade
                </div>
              </Link>
            </li>
            <li class="mr-2">
              <Link to="/dashboard/history">
                <div
                  class={`inline-block text-lg font-bold py-3 px-4 ${
                    pathname.includes("history")
                      ? "bg-[#034B5E] text-[#F8AD15]"
                      : " bg-transparent"
                  } rounded-3xl active`}
                  onClick={(e) => {
                    setActiveTab(2);
                  }}
                >
                  Transactions
                </div>
              </Link>
            </li>
          </ul>
        </div>
        <Link to="/dashboard/sell/list-card">
          <div className="hidden md:block text-center pl-1">
            <button className="btn bg-[#F8AD15] btn-lg   text-white text-xl btn-warning">
              Start Gift-Card Trade
            </button>
          </div>
        </Link>
        <Link to="/dashboard/notification">
          <div className="hidden cursor-pointer md:block pl-2 indicator">
            <span className="indicator-item badge badge-warning">
              {notData?.length}
            </span>
            <div className="px-6 py-6 lg:px-4 lg:py-4 rounded-full bg-white">
              <img src="/icon-bell.svg" />
            </div>
          </div>
        </Link>

        {/* <div className="hidden md:block pl-2">
          <div className="px-6 py-6 lg:px-4 lg:py-4 rounded-full bg-white">
            <img src="/icon-bell.svg" />
          </div>
        </div> */}
        <div className="hidden md:block dropdown dropdown-end pt-1  pl-2">
          <img tabIndex={0} className="w-20 rounded-full" src="/avater2.svg" />
          <ul
            tabIndex={0}
            className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
          >
            <Link to="/dashboard/profile">
              <li className="cursor-pointer">
                <a>My Profile</a>
              </li>
            </Link>
            <li className="cursor-pointer" onClick={logOut}>
              <a>Sign Out</a>
            </li>
          </ul>
        </div>
      </div>

      <nav
        className={`${
          toggleMenu ? "bg-white" : ""
        } -bg-white lg:bg-transparent lg:pt-5`}
      >
        <div class="md:hidden -max-w-6xl mx-auto px-4 md:bg-transparent bg-white">
          <div class="flex justify-between items-center md:mx-20">
            <div class="md:hidden flex items-center">
              <button
                onClick={btnClick}
                class="outline-none mobile-menu-button"
              >
                <svg
                  class={`w-10 h-10 ${
                    toggleMenu ? "text-green-500" : "text-black"
                  } ${
                    toggleMenu ? "hover:text-green-500" : "hover:text-black"
                  } `}
                  x-show="!showMenu"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>
            <Link to="/">
              <div className="block md:hidden">
                <a href="#" class="flex items-center py-4 px-2">
                  <img src="/logo-black.svg" alt="Logo" class="  mr-2" />
                </a>
              </div>
            </Link>

            <Link to="/dashboard/sell/list-card">
              <div>
                <button className="btn btn-warning btn-md  text-white font-bold">
                  Start Trade
                </button>
              </div>
            </Link>

            <div className="dropdown dropdown-end pt-1  pl-2">
              <img tabIndex={0} className="w-20" src="/avater2.svg" />
              <ul
                tabIndex={0}
                className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
              >
                <Link to="/dashboard/profile">
                  <li className="cursor-pointer">
                    <a>My Profile</a>
                  </li>
                </Link>
                <li className="cursor-pointer" onClick={logOut}>
                  <a>Sign Out</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {toggleMenu && (
          <div class=" mobile-menu text-center">
            <ul class=" ">
              <li class="active">
                <Link to="/dashboard/overview">
                  <div
                    className={`inline-block transition duration-300  text-lg font-bold py-3 px-4  ${
                      pathname.includes("overview")
                        ? "bg-[#034B5E] text-[#F8AD15]"
                        : " bg-transparent"
                    } rounded-3xl active`}
                    aria-current="page"
                    onClick={(e) => {
                      setActiveTab(0);
                    }}
                  >
                    Dashboard
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/trade">
                  <div
                    class={`inline-block transition duration-300  text-lg font-bold py-3 px-4 ${
                      pathname.includes("trade")
                        ? "bg-[#034B5E] text-[#F8AD15]"
                        : " bg-transparent"
                    } rounded-3xl active`}
                    aria-current="page"
                    onClick={(e) => {
                      setActiveTab(1);
                    }}
                  >
                    Gift Card Trade
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/history">
                  <div
                    class={`inline-block text-lg  transition duration-300 font-bold py-3 px-4 ${
                      pathname.includes("history")
                        ? "bg-[#034B5E] text-[#F8AD15]"
                        : " bg-transparent"
                    } rounded-3xl active`}
                    onClick={(e) => {
                      setActiveTab(2);
                    }}
                  >
                    Transactions
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </>
  );
}

export default DashboardHeader;
