import { Button } from "flowbite-react";
import React, { useState, useEffect } from "react";
import "./css/Navbar.css";
import { Link, useLocation } from "react-router-dom";
function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const location = useLocation();
  const { pathname } = location;

  // const handleToggle = () => {
  //   setToggleMenu(!toggleMenu);
  // };
  // const btn = document.querySelector("button.mobile-menu-button");
  // const menu = document.querySelector(".mobile-menu");
  const btnClick = () => {
    setToggleMenu(!toggleMenu);
  };
  // useEffect(() => {
  //   const menu = document.querySelector(".mobile-menu");
  //   menu.classList.toggle("hidden");
  // }, [toggleMenu]);
  return (
    <>
      <nav
        className={`${
          toggleMenu ? "bg-white" : ""
        } fixed w-full z-20 md:relative`}
      >
        <div class="max-w-7xl mx-auto px-4 md:bg-transparent bg-white ">
          <div class="flex justify-between xl:mx-16 my-0 md:my-4 ">
            <div className="md:block hidden">
              <a href="#" class="flex items-center py-4 px-2">
                <img src="/logo.svg" alt="Logo" class="  mr-2" />
              </a>
            </div>
            <div className="block md:hidden">
              <a href="#" class="flex items-center py-4 px-2">
                <img src="/IGCX-web-logo.svg" alt="Logo" class="  mr-2" />
              </a>
            </div>

            <div class="flex space-x-7">
              <div class="hidden md:flex items-center space-x-1">
                <Link to="/">
                  <div
                    class={`py-4 px-2 hover:text-yellow-300 transition duration-300 ${
                      pathname == "/"
                        ? "text-yellow-300 border-b-4 border-yellow-300"
                        : "text-white"
                    }  font-semibold`}
                  >
                    Home
                  </div>
                </Link>

                <a
                  href="/rate"
                  class={`py-4 px-2 hover:text-yellow-300 transition duration-300 ${
                    pathname == "/rate"
                      ? "text-yellow-300 border-b-4 border-yellow-300"
                      : "text-white"
                  }  font-semibold`}
                >
                  Rate
                </a>

                <a
                  href="/contact-us"
                  class={`py-4 px-2 hover:text-yellow-300 transition duration-300 ${
                    pathname == "/contact-us"
                      ? "text-yellow-300 border-b-4 border-yellow-300"
                      : "text-white"
                  }  font-semibold`}
                >
                  Contact Us
                </a>
              </div>
            </div>
            <Link to="/login">
              <div class="hidden md:flex items-center space-x-3 ">
                <div className="rounded-md ">
                  <div className="w-fit flex rounded-full items-center justify-center  border border-white text-base  font-normal   text-white -bg-indigo-600 hover:bg-yellow-300 focus:outline-none focus:border-yellow-300 focus:shadow-outline-yellow transition duration-150 ease-in-out ">
                    <button className="md:text-base text-sm text-center py-3 px-10">
                      {" "}
                      Login
                    </button>
                  </div>
                </div>
                <Link to="/signup">
                  <div className="w-fit  flex rounded-full items-center justify-center  border border-white text-base  font-regular   text-black bg-[#FFFFFF] hover:bg-yellow-300 focus:outline-none focus:border-yellow-300 focus:shadow-outline-yellow transition duration-150 ease-in-out ">
                    <button className="md:text-base text-sm text-center py-3 px-5">
                      {" "}
                      Create an Account
                    </button>
                  </div>
                </Link>
              </div>
            </Link>

            <div class="md:hidden flex items-center">
              <button onClick={btnClick} class="outline-none text-[#000]">
                <svg
                  // class={`w-6 h-6 ${toggleMenu ? "text-black" : ""}`}
                  className={`w-6 h-6 }
                    // toggleMenu ? "hover:text-red-500" : "hover:text-black"
                  } `}
                  x-show="!showMenu"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div
        className={`${
          toggleMenu ? "navOpenDropDown " : "navOpenCloseDown "
        }   md:hidden block fixed w-full  z-10   `}
      >
        <ul class=" ">
          <li class="active">
            <a
              href="/"
              class={`transition duration-300 ${
                pathname == ""
                  ? "text-[#F8AD15] border-b-[#F8AD15] border border-transparent`"
                  : "text-black"
              } inline-block text-base px-2 py-3 font-semibold`}
            >
              Home
            </a>
          </li>
          <li>
            <a
              href="/rate"
              class={`transition duration-300 ${
                pathname == "/rate"
                  ? "text-[#F8AD15] border-b-[#F8AD15] border border-transparent`"
                  : "text-black"
              } inline-block text-base px-2 py-3 font-semibold`}
            >
              Rate
            </a>
          </li>
          <li>
            <a
              href="/contact-us"
              class={`transition duration-300 ${
                pathname == "/contact-us"
                  ? "text-[#F8AD15] border-b-[#F8AD15] border border-transparent`"
                  : "text-black"
              } inline-block text-base px-2 py-3 font-semibold`}
            >
              Contact Us
            </a>
          </li>

          <li>
            <a
              href="/login"
              class="my-3 px-8 border border-[#000] rounded-full text-[#000] hover:cursor-pointer inline-block text-sm  py-3 text-black hover:bg-yellow-300 transition duration-300"
            >
              login
            </a>
          </li>

          <li>
            <a
              href="/signup"
              class="px-6 border rounded-full text-[#fff] bg-[#000] inline-block text-sm  py-3 text-black hover:bg-yellow-300 transition duration-300"
            >
              Create an Account{" "}
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navbar;
