import axios from "axios";
import React, { useState, useEffect } from "react";
import ImageModal from "./modals/ImageModal";

export default function ImageSetting() {
  const [profileData, setProfileData] = useState();
  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = () => {
    const token = localStorage.getItem("giftToken");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}api/v1/customer_profile`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        setProfileData(response.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      <ImageModal />
      <div className="max-w-[478px] h-[104px] rounded-xl px-2  bg-white">
        <div className="flex py-5 justify-between items-center">
          <div>
            <div className="inline-flex items-center">
              <div className="h-[50px] flex justify-center w-[50px] rounded-full bg-[#034B5E]">
                <img src="/user.svg" className="px-4" />
              </div>
              <div className="text-lg pl-2   w-[150px] md:w-full capitalize text-gray-400 font-bold">
                {profileData?.fullname}
                <div className="text-sm truncate text-gray-400 font-light">
                  {profileData?.email}
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            <label
              htmlFor="my-modal-8"
              className="btn rounded-3xl text-sm btn-info bg-[#CEEDFF] modal-button"
            >
              Edit
            </label>
          </div> */}
        </div>
      </div>
    </>
  );
}
