import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function PasswordModal() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [showConfirm, setShowConfirm] = useState(false);
  const onSubmit = (data) => {
    setLoading("loading");
    const token = localStorage.getItem("giftToken");

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API}api/v1/password_update`,
      headers: { Authorization: `Bearer ${token}` },
      data: { ...data },
    };

    axios(config)
      .then(function (response) {
        setLoading("");
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.error) {
          setLoading("");
          toast.error(error?.response?.data?.error[0], {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        } else {
          setLoading("");
          toast.error(error?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  return (
    <>
      {/* <label htmlFor="my-modal-5" className="btn modal-button">open modal</label> */}

      <input type="checkbox" id="my-modal-2" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box w-11/12 max-w-[662px] h-[650px]">
          <label for="my-modal-2" class="   absolute left-2 top-2">
            <img src="/arrow-right.svg" className="h-8 w-8 font-bold" />
          </label>
          <div className="font-black text-center text-gray-400 py-4 text-2xl">
            Change your Password
            {/* <div className="text-gray-400 text-sm font-light">
              Use the reset token that was sent to your email address to change
              your password
            </div> */}
          </div>
          <div>
            <div className="alert max-w-[459px] mx-auto">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="stroke-info flex-shrink-0 w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span className="md:text-sm text-2xs">
                  Changing your verified email WILL NOT ALLOW you to make a
                  TRANSACTION on your account for the next 24 - 48hrs
                </span>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="py-5">
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    OLD PASSWORD
                  </label>
                  <img
                    src="/eye-icon.svg"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-5 top-[64%] w-8 h-8 transform -translate-y-1/2"
                  />

                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-email"
                    type={!showPassword ? "password" : "text"}
                    placeholder="password"
                    required
                    {...register("oldpassword")}
                  />
                  <div className="absolute text-right"></div>
                </div>
              </div>

              <div class="flex   flex-wrap -mx-4 mb-2">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    NEW PASSWORD
                  </label>
                  <img
                    src="/eye-icon.svg"
                    onClick={() => setShowConfirm(!showConfirm)}
                    className="absolute right-5 top-[64%] w-8 h-8 transform -translate-y-1/2"
                  />

                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-email"
                    type={!showConfirm ? "password" : "text"}
                    placeholder="password"
                    required
                    {...register("newpassword")}
                  />
                  <div className="absolute text-right"></div>
                </div>
              </div>
              <div class="flex   flex-wrap -mx-4 mb-2">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    CONFIRM NEW PASSWORD
                  </label>
                  <img
                    src="/eye-icon.svg"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-5 top-[64%] w-8 h-8 transform -translate-y-1/2"
                  />

                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-email"
                    type={!showPassword ? "password" : "text"}
                    placeholder="password"
                    required
                    {...register("newpassword_confirmation")}
                  />
                  <div className="absolute text-right"></div>
                </div>
              </div>
              {/* <div class="flex   flex-wrap -mx-4 mb-2">
              <div class="w-full relative px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-city"
                >
                  EMAIL VERIFICATION CODE
                </label>

                <input
                  class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                  id="grid-email"
                  type={!showPassword ? "password" : "text"}
                  placeholder="Email  Confirmation Code"
                />
              </div>
            </div> */}
            </div>
            <div className="text-center w-full">
              <button className={`${loading} btn  lg:w-1/2 w-full`}>
                Update
              </button>
            </div>
          </form>

          {/* <div className="modal-action">
            <label htmlFor="my-modal-5" className="btn">
              Yay!
            </label>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default PasswordModal;
