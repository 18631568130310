import axios from "axios";
import React, { useEffect, useState } from "react";

function GiftCardForm() {
  const [cardsList, setCardsList] = useState([]);
  const [cardsCat, setCardsCat] = useState([]);
  const [selectCat, setSelectCat] = useState();
  const [cardAmount, setCardAmount] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [cardTypes, setCardTypes] = useState([]);
  const getCardList = () => {
    const token = localStorage.getItem("giftToken");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}api/v1/list-gift-cards`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        setCardsList(response.data?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getCardCat = (id) => {
    const token = localStorage.getItem("giftToken");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}api/v1/get-giftcards-details/${id}`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        setCardsCat(response.data?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getCardType = () => {
    const token = localStorage.getItem("giftToken");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}api/v1/get-card-types`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        setCardTypes(response.data?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleGiftCard = (e) => {
    getCardCat(e.target.value);
  };
  const handleCatChange = (e) => {
    const parsedData = JSON.parse(e.target.value);
    setSelectCat(parsedData);
  };
  const handleAmountChange = (e) => {
    setCardAmount(e.target.value);
  };
  const calculate = (e) => {
    e.preventDefault();

    setTotalAmount(+selectCat.gct_rate * +cardAmount);
  };

  useEffect(() => {
    getCardList();
    getCardType();
  }, []);
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className="bg-white md:py-10 h-full md:px-4">
      <div className="text-2xl py-3 text-[#298097] font-bold px-4">
        Calculate Gift Card
      </div>
      <div className="px-1">
        <div className="bg-white border border-gray-300 px-2 py-3 rounded-lg">
          <form>
            <label
              for="countries"
              class="block mb-2 text-sm font-medium pt-2 text-gray-900 dark:text-gray-400"
            >
              Select Gift Card<span className="text-red-600"> * </span>
            </label>
            <select
              onChange={handleGiftCard}
              id="countries"
              required
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option selected disabled>
                Select a Gift Card
              </option>
              {cardsList.map((data, i) => (
                <option key={i} value={data?.gc_id}>
                  {data?.gc_name}
                </option>
              ))}
            </select>
            <div>
              <label
                for="countries"
                class="block mb-2 text-sm font-medium pt-2 text-gray-900 dark:text-gray-400"
              >
                Select Gift Card<span className="text-red-600"> * </span>
              </label>
              <select
                id="countries"
                required
                onChange={handleCatChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option selected disabled>
                  Select Card Category
                </option>
                {cardsCat.map((data, i) => (
                  <option key={i} value={JSON.stringify(data)}>
                    {data?.gct_name}{" "}
                    {`(${data?.gct_min_range} 
                      - ${data?.gct_max_range})`}
                  </option>
                ))}
              </select>
            </div>
            <div class="flex flex-wrap -mx-3 py-3 mb-6">
              <div class="w-1/2 px-3">
                <label
                  for="countries"
                  class="block mb-2 text-sm font-medium pt-2 text-gray-900 dark:text-gray-400"
                >
                  Select Card<span className="text-red-600"> * </span>
                </label>
                <select
                  id="countries"
                  required
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected disabled>
                    Select Card
                  </option>
                  {cardTypes?.map((data, i) => (
                    <option key={i} value={data?.gfc_name}>
                      {data?.gfc_name}
                    </option>
                  ))}
                </select>
              </div>
              <div class="w-1/2 px-3 ">
                <label
                  class="block mb-2 text-sm font-medium pt-2 text-gray-900 dark:text-gray-400"
                  for="grid-name"
                >
                  Enter Amount <span className="text-red-600">*</span>
                </label>
                <input
                  class="-appearance-none block w-full bg-white text-gray-700 border border-gray-300 rounded-xl py-6 px-4  mb-3 leading-tight focus:outline-none"
                  id="grid-name"
                  type="text"
                  required
                  onChange={handleAmountChange}
                  placeholder="Enter Amount"
                />
                {/* <p class="text-red-600 text-xs italic">
                  Make it as long and as crazy as you'd like
                </p> */}
              </div>
            </div>
            {/* <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-name"
              >
                Enter Amount <span className="text-red-600">*</span>
              </label>
              <input
                class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                id="grid-name"
                type="text"
                placeholder="Enter Amount"
              />
               
            </div>
          </div> */}
            <div className="text-center">
              <button
                onClick={calculate}
                disabled={!cardAmount?.trim() || !selectCat ? true : false}
                class={`${
                  !cardAmount?.trim() || !selectCat
                    ? "bg-gray-500"
                    : "bg-[#F8AD15]"
                }  text-xl  text-white font-bold py-4 px-10 rounded`}
              >
                Calculate
              </button>
            </div>
          </form>
          <div className="border-b-2 border-dashed border-gray-300 py-4"></div>
          <div className="pt-4">Rate {selectCat?.gct_rate}/USD</div>
          <div className="flex items-center justify-between">
            <div>
              <div className="text-xl font-black">Total Payout</div>
            </div>
            <div className="font-black text-lg">
              ₦{numberWithCommas(totalAmount || 0)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GiftCardForm;
