import React, { useState } from "react";
import Navbar from "../components/header/Navbar";
import Footer from "../components/footer/Footer";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
function ContactUs() {
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState();
  const onSubmit = (data) => {
    setLoading("loading");
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API}api/notification/contact`,
      headers: {},
      data: { ...data },
    };

    axios(config)
      .then(function (response) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading("");
      })
      .catch(function (error) {
        if (error?.response?.data?.error) {
          setLoading("");
          toast.error(error?.response?.data?.error[0], {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        } else {
          setLoading("");
          toast.error(error?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div class="relative h-[317px]  w-full text-center bg-cover bg-center bg-[#034B5E]">
        <div className="absolute w-full lg:pt-3">
          <Navbar />
        </div>

        <main class="  pt-40 ">
          <div className="text-center font-black text-white text-3xl lg:text-4xl">
            Contact Us
          </div>
        </main>
      </div>
      <div className="py-2 px-2">
        <div className="py-10">
          <div className="flex md:flex-row flex-col py-2 justify-evenly bg-[#F0F5FF]">
            <div>
              <div className="font-black text-3xl py-2 text-center px-2">
                Contact Us
              </div>
              <div className="text-gray-500 text-lg py-3 ">
                Get in touch and let us know how to help us Send us a Message
                (Form)
              </div>

              <div className="py-6 px-6 lg:px-0">
                <ul class="list-disc marker:text-yellow-300">
                  <li className="py-2 text-base">
                    Phone: 08086961452 & 09152666295{" "}
                  </li>
                  <li className="py-2 text-base">Whatapp: +2348086961452 </li>
                  <li className="py-2 text-base">
                    Instagram: igcxhq_exchange{" "}
                  </li>
                  <li className="py-2 text-base">Email: igcxhq@gmail.com </li>
                </ul>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="rounded-lg bg-white px-4 py-4">
                <div class="flex flex-wrap py-2 -mx-4 mb-2">
                  <div class="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-city"
                    >
                      Name
                    </label>
                    <input
                      class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                      id="grid-email"
                      type="text"
                      placeholder="Name"
                      {...register("name")}
                    />
                  </div>
                  <div class="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-phone"
                    >
                      Email
                    </label>
                    <input
                      class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                      id="grid-phone"
                      type="email"
                      placeholder="Email"
                      {...register("email")}
                    />
                  </div>
                </div>

                <div class="flex flex-wrap py-2 -mx-4 mb-2">
                  <div class="w-full px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-city"
                    >
                      Subject
                    </label>
                    <input
                      class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                      id="grid-email"
                      type="text"
                      placeholder="Subject"
                      {...register("subject")}
                    />
                  </div>
                  {/* <div class="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-phone"
                  >
                    Transaction ID
                  </label>
                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-phone"
                    type="email"
                    placeholder="Transaction ID"
                  />
                </div> */}
                </div>

                <div class="flex flex-wrap py-2 -mx-4 mb-2">
                  <div class="w-full px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-city"
                    >
                      Message
                    </label>
                    <textarea
                      class="-appearance-none block resize-none w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                      id="grid-email"
                      type="text"
                      placeholder="Message"
                      {...register("message")}
                    />
                  </div>
                </div>
                <div className="text-left w-full">
                  <button
                    className={`${loading} btn btn-warning lg:w-1/2 w-full`}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="">
            <div className="flex md:flex-row flex-col justify-evenly items-center">
              <div>
                <img src="/contact-img.svg" />
              </div>
              <div className="rounded-lg bg-white px-4 py-4">
                <div className="font-black text-xl py-3">Call Us</div>
                <div className="px-2 text-base">
                  Call us to speak to a member of our team. We are always happy
                  to help. +2348086961452 & +2349152666295
                </div>

                <div className="font-black text-xl py-3">Support </div>
                <div>
                  Get realtime support with our live chat feature. (whatsapp
                  chat, live chat)
                </div>

                <div className="font-black text-xl py-3">Email Us </div>
                <div>
                  Email us for general queries, including difficulties and any
                  other problem. suport@igcex.ng
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
