import React, { useState } from "react";

function ImageModal() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState();
  return (
    <>
      {/* <label htmlFor="my-modal-5" className="btn modal-button">open modal</label> */}

      <input type="checkbox" id="my-modal-8" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box w-11/12 max-w-[662px] h-[650px]">
          <label for="my-modal-8" class="   absolute left-2 top-2">
            <img src="/arrow-right.svg" className="h-8 w-8 font-bold" />
          </label>
          <div className="font-black text-center text-gray-400 py-4 text-2xl">
            Edit Profile
            <div className="text-gray-400 text-sm font-light">
              Use this form to edit your profile
            </div>
          </div>
          <div className="flex justify-center items-center">
            <img src="/avater.svg" />
          </div>
          <div className="py-5">
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-name"
                >
                  First Name <span className="text-red-600">*</span>
                </label>
                <input
                  class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                  id="grid-name"
                  type="text"
                  placeholder="First Name"
                />
                {/* <p class="text-red-600 text-xs italic">
                  Make it as long and as crazy as you'd like
                </p> */}
              </div>
            </div>

            <div class="flex   flex-wrap -mx-4 mb-2">
              <div class="w-full relative px-3 mb-6 md:mb-0">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-city"
                >
                  Last Name
                </label>

                <input
                  class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                  id="grid-email"
                  type={!showPassword ? "password" : "text"}
                  placeholder="Last Name"
                />
                <div className="absolute text-right"></div>
              </div>
            </div>
          </div>
          <div className="text-center w-full">
            <button className={`${loading} btn  lg:w-1/2 w-full`}>
              Update
            </button>
          </div>

          {/* <div className="modal-action">
            <label htmlFor="my-modal-5" className="btn">
              Yay!
            </label>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default ImageModal;
