import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import ReasonModal from "./ReasonModal";
function Trade() {
  const [trade, setTrade] = useState([]);
  const Per_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState();
  const [reasonData, setReasonData] = useState();
  const getActiveTrade = () => {
    const token = localStorage.getItem("giftToken");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}api/v1/get-orders?page=${currentPage}`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        setTrade(response.data?.data?.data);
        setTotal(response?.data?.data?.total);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getActiveTrade();
  }, [currentPage]);
  const pageCount = Math.ceil(total / Per_PAGE);
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getData = (data) => {
    setReasonData(data);
  };

  return (
    <div className="pt-32 md:pt-36 md:px-20  max-w-7xl mx-auto px-3">
      <div className="bg-white md:px-4 ">
        <div className="font-black text-xl text-[#298097] md:px-4 py-4 ">
          Gift Card Trades
        </div>
        <ReasonModal reasonData={reasonData} />
        <div class="hidden md:block overflow-x-auto relative border border-gray-300 p-4 rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 border-b border-gray-300 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="py-3 px-6 font-black text-[#298097]">
                  Card Type
                </th>
                <th scope="col" class="py-3 font-black text-[#298097] px-6">
                  Total Amount
                </th>
                <th scope="col" class="py-3 font-black text-[#298097] px-6">
                  Status
                </th>
                <th scope="col" class="py-3 font-black text-[#298097] px-6">
                  Reason
                </th>
                <th
                  scope="col"
                  class="py-3 font-black text-[#298097] font-blacktext-[#298097] px-6"
                >
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {trade?.map((data, i) => (
                <tr class="bg-white border-b-2 dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {data?.gift_card_name?.gc_name}
                  </th>
                  <td class="py-4 px-6">
                    {numberWithCommas(data?.pr_amount_total.split(".")[0] || 0)}
                  </td>
                  <td class="py-4 px-6 capitalize"> {data?.pr_status}</td>

                  <td class="py-4 px-6 font-normal capitalize">
                    {data?.pr_status == "declined" && (
                      <label
                        htmlFor="reason-modal"
                        className="btn font-thin btn-sm "
                        onClick={() => getData(data)}
                      >
                        View
                      </label>
                    )}
                  </td>

                  <td class="py-4 px-6">
                    {new Date(data?.updated_at).toDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="md:hidden border-gray-300 border py-1 px-2 rounded-lg">
          {trade?.map((data, i) => (
            <div className="">
              <div className="font-black"> {data?.gift_card_name?.gc_name}</div>
              <div className="flex text-sm justify-between">
                <div>
                  {numberWithCommas(data?.pr_amount_total.split(".")[0] || 0)}
                </div>
                <div className="capitalize"> {data?.pr_status}</div>
                <div> {new Date(data?.updated_at).toDateString()}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pt-5">
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName="pagination justify-center"
          previousClassName=""
          nextLinkClassName=""
          disabledClassName=""
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
}

export default Trade;
