import React from "react";

function Cards() {
  return (
    <div className="-max-w-6xl mx-auto">
      <div className="lg:pb-10 md:pb-8 pb-6 text-[#2A2A2A] text-center font-bold text-base lg:text-3xl md:text-3xl  font-extrabold w-[90%] lg:w-[60%] md:[80%] xl:w-[35%] mx-auto text-black">
        Sell faster and get instant payment with our super easy-to-use platform.
      </div>
      <div className="cardGrid">
        <div className="py-6 card w-[16rem] bg-[#fff] border border-[#C4C4C4] shadow-xl">
          <figure className=" ">
            <img src="/icon-1.svg" alt="arrow" className="rounded-xl" />
          </figure>
          <div className="card-body p-2 text-center">
            <h2 className="text-[#474747] font-bold md:text-lg text-base">
              SPEEDY PAYMENT
            </h2>
            <p className="md:text-base text-xs text-[#474747] w-[80%]  mx-auto  ">
              We don't joke with how fast you receive your payment. Once your
              card is verified, WE PAY OUT INSTANTLY
            </p>
            <div className="card-actions items-center justify-center pt-10">
              {/* <button className="btn bg-[#298097] border-[#298097] font-normal px-10 text-white md:text-base text-xs">
                Read More
              </button> */}
            </div>
          </div>
        </div>
        <div className=" py-6 card w-[16rem] bg-[#fff] border border-[#C4C4C4] shadow-xl">
          <figure className=" ">
            <img src="/icon-2.svg" alt="arrow" className="rounded-xl" />
          </figure>
          <div className="card-body p-2 text-center">
            <h2 className="text-[#474747] font-bold md:text-lg text-base">
              SAFE AND SECURE
            </h2>
            <p className="md:text-base text-xs text-[#474747] w-[80%]  mx-auto ">
              Transactions are secure. At IGCX, security is always our priority.{" "}
            </p>
            <div className="card-actions items-center justify-center pt-10">
              {/* <button className="btn bg-[#298097] border-[#298097] font-normal px-10 text-white md:text-base text-xs">
                Read More
              </button> */}
            </div>
          </div>
        </div>
        <div className=" py-6 card w-[16rem] bg-[#fff] border border-[#C4C4C4] shadow-xl">
          <figure className=" ">
            <img src="/icon-3.svg" alt="arrow" className="rounded-xl" />
          </figure>
          <div className="card-body p-2 text-center">
            <h2 className="text-[#474747] font-bold md:text-lg text-base">
              CUSTOMER FRIENDLY
            </h2>
            <p className="md:text-base text-xs text-[#474747] w-[80%]  mx-auto ">
              IGCX is designed and simplified for beginners and experienced gift
              cards traders.{" "}
            </p>
            <div className="card-actions items-center justify-center pt-10">
              {/* <button className="btn bg-[#298097] border-[#298097] font-normal px-10 text-white md:text-base text-xs">
                Read More
              </button> */}
            </div>
          </div>
        </div>
        <div className=" py-6 card w-[16rem] bg-[#fff] border border-[#C4C4C4] shadow-xl">
          <figure className=" ">
            <img src="/icon-4.svg" alt="arrow" className="rounded-xl" />
          </figure>
          <div className="card-body p-2 text-center">
            <h2 className="text-[#474747] font-bold md:text-lg text-base">
              CURRENT RATE
            </h2>
            <p className="md:text-base text-xs text-[#474747] w-[80%]  mx-auto ">
              Our rates are auto-updated. Always current rates is displayed on
              igcex.ng{" "}
            </p>
            <div className="card-actions items-center justify-center pt-10">
              {/* <button className="btn bg-[#298097] border-[#298097] font-normal px-10 text-white md:text-base text-xs">
                Read More
              </button> */}
            </div>
          </div>
        </div>
      </div>

      <div className="hidden lg:flex items-center lg:items-stretch md:flex-col flex-row lg:flex-row pt-9 justify-center lg:px-10  gap-y-4 lg:space-x-8 ">
        <div className=" py-6 card w-[16rem] bg-[#fff] border border-[#C4C4C4] shadow-xl">
          <figure className=" ">
            <img src="/icon-1.svg" alt="arrow" className="rounded-xl" />
          </figure>
          <div className="card-body p-2 text-center">
            <h2 className="text-[#474747] font-bold md:text-lg text-base">
              SPEEDY PAYMENT
            </h2>
            <p className="md:text-base text-sm text-[#474747] ">
              We don't joke with how fast you receive your payment. Once your
              card is verified, WE PAY OUT INSTANTLY
            </p>
            <div className="card-actions items-center justify-center pt-10">
              {/* <button className="btn bg-[#298097] border-[#298097] font-normal px-10 text-white md:text-base text-xs">
                Read More
              </button> */}
            </div>
          </div>
        </div>
        <div className=" py-6 card w-[16rem] bg-[#fff] border border-[#C4C4C4] shadow-xl">
          <figure className=" ">
            <img src="/icon-2.svg" alt="arrow" className="rounded-xl" />
          </figure>
          <div className="card-body p-2 text-center">
            <h2 className="text-[#474747] font-bold md:text-lg text-base">
              SAFE AND SECURE
            </h2>
            <p className="md:text-base text-sm text-[#474747] ">
              Transactions are secure. At IGCX, security is always our priority.{" "}
            </p>
            <div className="card-actions items-center justify-center pt-10">
              {/* <button className="btn bg-[#298097] border-[#298097] font-normal px-10 text-white md:text-base text-xs">
                Read More
              </button> */}
            </div>
          </div>
        </div>
        <div className=" py-6 card w-[16rem] bg-[#fff] border border-[#C4C4C4] shadow-xl">
          <figure className=" ">
            <img src="/icon-1.svg" alt="arrow" className="rounded-xl" />
          </figure>
          <div className="card-body p-2 text-center">
            <h2 className="text-[#474747] font-bold md:text-lg text-base">
              SPEEDY PAYMENT
            </h2>
            <p className="md:text-base text-sm text-[#474747] ">
              IGCX is designed and simplified for beginners and experienced gift
              cards traders.{" "}
            </p>
            <div className="card-actions items-center justify-center pt-10">
              {/* <button className="btn bg-[#298097] border-[#298097] font-normal px-10 text-white md:text-base text-xs">
                Read More
              </button> */}
            </div>
          </div>
        </div>
        <div className=" py-6 card w-[16rem] bg-[#fff] border border-[#C4C4C4] shadow-xl">
          <figure className=" ">
            <img src="/icon-3.svg" alt="arrow" className="rounded-xl" />
          </figure>
          <div className="card-body p-2 text-center">
            <h2 className="text-[#474747] font-bold md:text-lg text-base">
              CURRENT RATES
            </h2>
            <p className="md:text-base text-sm text-[#474747]">
              Our rates are auto-updated. Always current rates is displayed on
              igcex.ng{" "}
            </p>
            <div className="card-actions items-center justify-center pt-10">
              {/* <button className="btn bg-[#298097] border-[#298097] font-normal px-10 text-white md:text-base text-xs">
                Read More
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
