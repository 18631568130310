import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Signup() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const [showConfirm, setShowConfirm] = useState(false);
  const watchPolicy = watch("policy", false);
  const [showPassword, setShowPassword] = useState(false);
  const onSubmit = (data) => {
    setLoading("loading");
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API}api/auth/register_profile`,
      headers: {},
      data: { ...data },
    };

    axios(config)
      .then(function (response) {
        setLoading("");
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        localStorage.setItem("giftEmail", data.email);
        navigate("/verify");
      })
      .catch(function (error) {
        if (error?.response?.data?.error) {
          setLoading("");
          toast.error(error?.response?.data?.error[0], {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        } else {
          setLoading("");
          toast.error(error?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex items-center -justify-center ">
        <div className="lg:w-1/2 lg:px-7 w-full">
          <div className="font-extraboleronmonsele@gmail.comd text-[#034B5E] py-12 px-8 text-3xl">
            Get Started
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit)} class="w-full px-4">
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-name"
                  >
                    Full Name <span className="text-red-600">*</span>
                  </label>
                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-name"
                    type="text"
                    placeholder="Full Name"
                    required
                    {...register("fullname")}
                  />
                  {/* <p class="text-red-600 text-xs italic">
                    Make it as long and as crazy as you'd like
                  </p> */}
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-email"
                  >
                    Email Address <span className="text-red-600">*</span>
                  </label>
                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-email"
                    type="text"
                    placeholder="Email Address"
                    required
                    {...register("email")}
                  />
                  {/* <p class="text-gray-600 text-xs italic">
                    Make it as long and as crazy as you'd like
                  </p> */}
                </div>
              </div>
              <div class="flex flex-wrap -mx-4 mb-2">
                {/* <div class="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    Birth Date
                  </label>
                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-email"
                    type="date"
                    placeholder="Email Address"
                    required
                    {...register("dob")}
                  />
                </div> */}
                <div class="w-full md:w-full px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-phone"
                  >
                    Phone Number
                  </label>
                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-phone"
                    type="text"
                    placeholder="Phone Number"
                    required
                    {...register("phone_no")}
                  />
                  {/* <div class="relative">
                    <select
                      class="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-state"
                    >
                      <option>New Mexico</option>
                      <option>Missouri</option>
                      <option>Texas</option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        class="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div> */}
                </div>
              </div>
              <div class="flex flex-wrap -mx-4 mb-2">
                <div class="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0 relative">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    Password
                  </label>
                  <img
                    src="/eye-icon.svg"
                    className="absolute right-5 lg:top-[60%] top-[60%] md:w-8 md:h-8 h-5 w-5 transform -translate-y-1/2"
                    onClick={() => setShowPassword(!showPassword)}
                  />

                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-email"
                    type={!showPassword ? "password" : "text"}
                    placeholder="password"
                    required
                    {...register("password")}
                  />
                  <div className="absolute text-right"></div>
                </div>
                <div class="w-1/2 md:w-1/2 px-3 mb-6 md:mb-0 relative">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    Confirm Password
                  </label>
                  <img
                    src="/eye-icon.svg"
                    onClick={() => setShowConfirm(!showConfirm)}
                    className="absolute right-5 lg:top-[60%] top-[60%] md:w-8 md:h-8 h-5 w-5 transform -translate-y-1/2"
                  />

                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-email"
                    type={!showConfirm ? "password" : "text"}
                    placeholder="Confirm password"
                    required
                    {...register("password_confirmation")}
                  />
                  <div className="absolute text-right"></div>
                </div>
              </div>
              <div className="flex py-4 items-center">
                <div>
                  <input
                    type="checkbox"
                    //checked="checked"
                    className="checkbox"
                    {...register("policy")}
                  />
                </div>
                <div className="pl-2 text-sm ">
                  I Agree To IGCX{" "}
                  <Link to="/policy">
                    <span className="text-yellow-300 cursor-pointer">
                      Privacy Policy
                    </span>
                  </Link>{" "}
                  & <span className="text-yellow-300">Terms.</span>
                </div>
              </div>
              <div className="text-center w-full">
                <button
                  disabled={watchPolicy == false ? true : false}
                  className={`${loading} btn btn-warning lg:w-1/2 w-full`}
                >
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="hidden md:block w-1/2 h-screen bg-gradient-to-r from-[#267E92] to-[#267E92] ">
          <Link to="/">
            <div className="py-11 px-6 ">
              <img
                src="/logo.svg"
                className="py-4 px-6 rounded-3xl bg-[#034B5E]"
              />
            </div>
          </Link>

          <div className=" text-3xl md:text-2xl lg:text-3xl xl:text-5xl py-3 text-white font-bold px-4">
            Creating a{" "}
            <span className="text-[#F8AD15] font-bold">Secure Account</span> to
            start trading
          </div>
          <div className="flex justify-center py-3 items-center">
            <img src="/bg-3.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
