import axios from "axios";
import React, { useEffect, useState } from "react";
import BankDetailModal from "./modals/BankDetails";
import ViewBankDetails from "./modals/ViewBankDetails";

function Verify() {
  const [bankDetail, setBankDetail] = useState([]);
  const [showModal, setShowModal] = useState(true);
  useEffect(() => {
    getBankDetail();
  }, []);

  const getBankDetail = () => {
    const token = localStorage.getItem("giftToken");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}api/v1/list_customers_bank`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        setBankDetail(response.data?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div>
      {showModal && (
        <BankDetailModal
          setShowModal={setShowModal}
          getBankDetail={getBankDetail}
        />
      )}
      <ViewBankDetails getBankDetail={getBankDetail} bankDetail={bankDetail} />

      <div className="max-w-[432px] rounded-xl h-[530px] bg-white px-3">
        <div className="py-4 px-1 font-black text-2xl">
          Bank Details
          <div className="font-light text-sm">Set your bank details</div>
        </div>
        <div className="flex py-5 justify-between items-center">
          <div>
            <div className="inline-flex items-center">
              <div>
                {" "}
                <img src="/side-icon.svg" />
              </div>
              <div className="text-lg pl-2 text-gray-400 font-bold">
                Bank Details
              </div>
            </div>
          </div>

          {bankDetail?.length ? (
            <div>
              <label
                htmlFor="my-modal-30"
                className="btn btn-sm rounded-3xl text-sm btn-info bg-[#CEEDFF] modal-button"
              >
                View
              </label>
            </div>
          ) : (
            <div>
              <label
                onClick={() => setShowModal(true)}
                htmlFor="my-modal-14"
                className="btn btn-sm rounded-3xl text-sm btn-info bg-[#CEEDFF] modal-button"
              >
                Edit
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Verify;
