import React, { useEffect, useState } from "react";
import ActiveTrade from "./ActiveTrade";
import GiftCardForm from "./GiftCardForm";
import Wallet from "./Wallet";
import axios from "axios";

function DashboardOverview() {
  return (
    <div className="flex px-4 md:space-x-6 md:pt-20 items-start md:justify-evenly flex-col md:flex-row">
      <div className="md:w-2/5 w-full h-full">
        <div className="md:pt-7 pt-20 -w-full">
          <Wallet />
        </div>
        {/* <div className="pt-7">
          <ActiveTrade />
        </div> */}
      </div>
      <div className="pt-7 h-full  md:w-3/5">
        <GiftCardForm />
      </div>
    </div>
  );
}

export default DashboardOverview;
