import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
function TransactionHistory() {
  const [trade, setTrade] = useState([]);
  const Per_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState();
  const getActiveTrade = () => {
    const token = localStorage.getItem("giftToken");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}api/v1/withdrawal-history?page=${currentPage}`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        setTrade(response.data?.data?.data);
        setTotal(response?.data?.data?.total);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getActiveTrade();
  }, []);
  const pageCount = Math.ceil(total / Per_PAGE);
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage + 1);
  }

  return (
    <div className="pt-32 md:pt-36 md:px-20  max-w-7xl mx-auto px-3">
      {trade?.length ? (
        <>
          <div className="bg-white md:px-4 ">
            <div className="font-black text-xl text-[#298097] md:px-4 py-4 ">
              Transaction History
            </div>
            <div class=" md:block overflow-x-auto relative border border-gray-300 p-4 rounded-lg">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 border-b border-gray-300 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-3 px-6 font-black text-[#298097]">
                      Account Name
                    </th>
                    <th scope="col" class="py-3 font-black text-[#298097] px-6">
                      Account Number
                    </th>
                    <th scope="col" class="py-3 font-black text-[#298097] px-6">
                      Bank Name
                    </th>
                    <th scope="col" class="py-3 font-black text-[#298097] px-6">
                      Amount
                    </th>
                    <th scope="col" class="py-3 font-black text-[#298097] px-6">
                      Status
                    </th>
                    <th
                      scope="col"
                      class="py-3 font-black text-[#298097] font-blacktext-[#298097] md:px-6"
                    >
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {trade?.map((data, i) => (
                    <tr class="bg-white border-b-2 dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {data?.customerBank?.bank_account_name}
                      </th>
                      <td class="py-4 px-6">
                        {data?.customerBank?.bank_account_number}
                      </td>

                      <td class="py-4 px-6">{data?.customerBank?.bank_name}</td>
                      <td class="py-4 px-6">{data?.wd_amount}</td>
                      <td class="py-4 px-6 capitalize"> {data?.wd_status}</td>
                      <td class="py-4 px-6">
                        {new Date(data?.updated_at).toDateString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="pt-5">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName="pagination justify-center"
              previousClassName=""
              nextLinkClassName=""
              disabledClassName=""
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </>
      ) : (
        <div className="bg-white md:px-4 pt-20  px-3 max-w-7xl mx-auto ">
          <div className="font-black text-2xl text-[#298097] md:px-4 py-4">
            All Transaction
          </div>
          <div className="border border-gray-300 rounded-lg lg:h-[636px] h-[500px] ">
            <div className="flex items-center justify-center pt-44 -h-screen text-center">
              <img src="/empty.svg" />
            </div>
            <div className="text-center">
              You are yet to make any transaction
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TransactionHistory;
