import React from "react";
import { Link } from "react-router-dom";

function GiftCards() {
  const cards = [
    "/card-1.svg",
    "/card-2.svg",
    "/card-3.svg",
    "/card-4.svg",
    "/card-5.svg",
    "/card-6.svg",
    "/card-7.svg",
    "/card-8.svg",
    "/card-9.svg",
    "/card-10.svg",
    "/card-11.svg",
    "/card-12.svg",
  ];
  return (
    <div className="md:pt-20 pt-10 ">
      <div className="max-w-6xl mx-auto">
        <div className="text-center py-4">
          <p className="text-[#000000]  font-bold text-base lg:text-2xl md:text-3xl">
            {" "}
            Gift Cards we Buy
          </p>
          <div className="md:text-base md:pb-14 md:pt-4 py-2 md:w-full md:w-[85%] mx-auto text-xs w-[70%] mx-auto">
            We buy a whole lot of gift cards from the USA and many other
            countries.
          </div>
        </div>
        <Link to="/dashboard/sell/list-card">
          <div className="grid md:grid-cols-3  grid-cols-2 md:gap-4 gap-3  lg:px-0 px-4">
            <div>
              <img src="/card-1.svg" />
              <p className="text-center text-[#474747] md:py-3 font-normal py-1">
                Amazon
              </p>
            </div>
            <div>
              <img src="/card-2.svg" />
              <p className="text-center text-[#474747] md:py-3 font-normal py-1">
                American Express
              </p>
            </div>
            <div>
              <img src="/card-3.svg" />
              <p className="text-center text-[#474747] md:py-3 font-normal py-1">
                Apple store
              </p>
            </div>
            <div>
              <img src="/card-4.svg" />
              <p className="text-center text-[#474747] md:py-3 font-normal py-1">
                {" "}
                Steam
              </p>
            </div>
            <div>
              <img src="/card-5.svg" />
              <p className="text-center text-[#474747] md:py-3 font-normal py-1">
                {" "}
                Target
              </p>
            </div>
            <div>
              <img src="/card-6.svg" />
              <p className="text-center text-[#474747] md:py-3 font-normal py-1">
                {" "}
                Vanilla
              </p>
            </div>
            <div>
              <img src="/card-7.svg" />
              <p className="text-center text-[#474747] md:py-3 font-normal py-1">
                {" "}
                Nike
              </p>
            </div>
            <div>
              <img src="/card-8.svg" />
              <p className="text-center text-[#474747] md:py-3 font-normal py-1">
                {" "}
                Nordstorm
              </p>
            </div>
            <div>
              <img src="/card-9.svg" />
              <p className="text-center text-[#474747] md:py-3 font-normal py-1">
                {" "}
                Sephora
              </p>
            </div>
            <div>
              <img src="/card-10.svg" />
              <p className="text-center text-[#474747] md:py-3 font-normal py-1">
                {" "}
                Google play
              </p>
            </div>
            <div>
              <img src="/card-11.svg" />
              <p className="text-center text-[#474747] md:py-3 font-normal py-1">
                JCpenny
              </p>
            </div>
            <div>
              <img src="/card-12.svg" />
              <p className="text-center text-[#474747] md:py-3 font-normal py-1">
                {" "}
                iTunes
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default GiftCards;
