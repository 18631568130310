import React from "react";

function HowToTrade() {
  return (
    <div className="max-w-6xl mx-auto">
      <div className="uppercase  text-center  pt-7  lg:pb-14">
        <p className="text-[#000000] font-extrabold text-base md:text-3xl "> SIMPLE WAY TO START TRADING WITH US</p>
        <div className=" md:py-5 py-1 text-[#848996]  ">
          <p className="text-xs md:text-base w-[90%] mx-auto md:w-full">
            You must be wondering what our mode of operation is.
          </p>
        </div>
      </div>
      <div>
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-x-32 px-10 items-center lg:justify-center  pt-6 md:pt-11">
          <div>
            <img src="/img-1.svg" className="-px-16 lg:pb-0 pb-2" />
          </div>
          <div className="-px-6 lg:px-0 flex flex-col lg:items-start items-center">
            <div className="text-2xl font-bold text-black md:pb-4 pb-1">
              <p className="md:text-[24px] text-base font-[600px] font-bold">Create An Account</p>
            </div>
            <div className="lg:w-96 mx-auto w-[100%] mx-auto lg:text-xl text-[#848996] text-center lg:text-start">
              <p className=" text-xs md:text-base">
                Create an account with your valid details and add your bank account / mobile money.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-x-32 px-10 items-center lg:justify-center  pt-11">
          <div>
            <img src="/img-3.svg" className="-px-16 md:pb-0 pb-2" />
          </div>
          <div className="-px-6 lg:px-0 flex flex-col lg:items-start items-center">
            <div className="text-2xl font-bold text-black md:pb-4 pb-1">
              <p className="md:text-[24px] text-base font-[600px] font-bold">Begin Trade</p>
            </div>
            <div className="lg:w-96 mx-auto w-[100%] mx-auto lg:text-xl text-[#848996] text-center lg:text-start">
              <p className=" text-xs md:text-base">
                Start a trade by simply clicking on Sell Gift Card , select the gift card you want and upload it.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-x-32 px-10 items-center lg:justify-center  pt-11">
          <div>
            <img src="/img-2.svg" className="-px-16 md:pb-0 pb-2" />
          </div>
          <div className="-px-6 lg:px-0 flex flex-col lg:items-start items-center">
            <div className="text-2xl font-bold text-black md:pb-4 pb-1">
              <p className="md:text-[24px] text-base font-[600px] font-bold">Wait for Confirmation</p>
            </div>
            <div className="lg:w-96 mx-auto w-[100%] mx-auto lg:text-xl text-[#848996] text-center lg:text-start">
              <p className=" text-xs md:text-base">
                Start a trade by simply clicking on Sell Gift Card , select the gift card you want and upload it.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-x-32 px-10 items-center lg:justify-center  pt-11">
          <div>
            <img src="/img-4.svg" className="-px-16 md:pb-0 pb-2" />
          </div>
          <div className="-px-6 lg:px-0 flex flex-col lg:items-start items-center">
            <div className="text-2xl font-bold text-black md:pb-4 pb-1">
              <p className="md:text-[24px] text-base font-[600px] font-bold">Withdraw Funds</p>
            </div>
            <div className="lg:w-96 mx-auto w-[100%] mx-auto lg:text-xl text-[#848996] text-center lg:text-start">
              <p className=" text-xs md:text-base">
                You can withdraw your funds at anytime you want no hassle. Pretty easy right? Now sign up and begin
                trading like a boss
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowToTrade;
