import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
function ResetPassword() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const [showConfirm, setShowConfirm] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const onSubmit = (data) => {
    const Email = localStorage.getItem("reset-email");
    setLoading("loading");
    const PAYLOAD = {
      ...data,
      email: Email,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API}api/auth/change_password`,
      headers: {},
      data: { ...PAYLOAD },
    };

    axios(config)
      .then(function (response) {
        setLoading("");
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        localStorage.removeItem("reset-email");
        navigate("/login");
      })
      .catch(function (error) {
        if (error?.response?.data?.error) {
          setLoading("");
          toast.error(error?.response?.data?.error[0], {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        } else {
          setLoading("");
          console.log(error?.response?.data?.message);
          toast.error(error?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  return (
    <div className="bg-[#F8F8F8]">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="md:px-8 md:pt-40 bg-white -md:w-1/2 flex items-center  justify-center">
        <div className="pt-24 md:pt-16  md:border md:border-[#C4C4C4] md:rounded-2xl md:px-16">
          <div className="text-center font-extrabold text-[#034B5E] text-2xl">
            Reset Password
          </div>
          <div className="text-center text-xs">Create your new password</div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-2  pt-8 -md:h-screen">
              <div className="rounded-xl bg-white md:px-5   py-6 md:border md:border-[#C4C4C4]">
                <div class="flex flex-wrap px-2 pt-5 -mx-3 mb-6">
                  <div class="w-full px-3">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-token"
                    >
                      Reset Token <span className="text-red-600">*</span>
                    </label>
                    <input
                      class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                      id="grid-token"
                      type="text"
                      placeholder="Reset  Token"
                      required
                      {...register("otp")}
                    />
                    {/* <p class="text-red-600 text-xs italic">
                Make it as long and as crazy as you'd like
              </p> */}
                  </div>
                </div>
                <div class="flex px-2 flex-wrap -mx-4 mb-2">
                  <div class="w-full relative px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-city"
                    >
                      Password
                    </label>
                    <img
                      src="/eye-icon.svg"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-5 top-[55%] w-8 h-8 transform -translate-y-1/2"
                    />
                    <input
                      class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                      id="grid-email"
                      type={!showPassword ? "password" : "text"}
                      placeholder="password"
                      required
                      {...register("password")}
                    />
                  </div>
                </div>
                <div class="flex px-2   flex-wrap -mx-4 mb-1">
                  <div class="w-full relative px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="grid-city"
                    >
                      Confirm Password
                    </label>
                    <img
                      src="/eye-icon.svg"
                      onClick={() => setShowConfirm(!showConfirm)}
                      className="absolute right-5 top-[55%] w-8 h-8 transform -translate-y-1/2"
                    />
                    <input
                      class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                      id="grid-email"
                      type={!showConfirm ? "password" : "text"}
                      placeholder="Confirm Password"
                      required
                      {...register("password_confirmation")}
                    />
                  </div>
                </div>
              </div>
              <div className="text-center  pt-1  md:pt-14 w-full">
                <button
                  className={`btn btn-warning lg:w-full w-full ${loading}`}
                >
                  Reset Password
                </button>
              </div>
              <Link to="/login">
                <div className="cursor-pointer inline-flex pb-9 items-center">
                  <div>
                    <img src="/arrow.svg" />
                  </div>
                  <div className="pl-1">Back to sign in</div>
                </div>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
