import axios from "axios";
import React, { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import WithdrawModal from "./WithdrawModal";
function Wallet() {
  const [walletBalance, setWalletBalance] = useState();
  const [showModal, setShowModal] = useState(true);
  const getWalletBalance = () => {
    const token = localStorage.getItem("giftToken");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}api/v1/dashboard`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        setWalletBalance(response.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getWalletBalance();
  }, []);
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className="bg-white py-7 px-5">
      {showModal && (
        <WithdrawModal
          getWalletBalance={getWalletBalance}
          walletBalance={walletBalance?.customer_wallet_balance}
          setShowModal={setShowModal}
        />
      )}
      <div className="font-extrabold text-[#298097] text-2xl py-1">
        Main Balance
      </div>
      <div
        style={{ backgroundImage: `url(/card-icon.svg)` }}
        className="rounded-xl py-10 px-4 bg-no-repeat bg-cover md:py-20  flex items-center justify-evenly gap-x-10"
      >
        <div>
          <div className="text-white font-extrabold text-xl">NGN</div>
          <div className="text-white text-2xl font-extrabold">
            {numberWithCommas(walletBalance?.customer_wallet_balance || 0)}
          </div>
        </div>
        <div className="">
          <label
            onClick={() => setShowModal(true)}
            htmlFor="my-modal-1"
            class="bg-[#F8AD15] text-xl cursor-pointer  text-white font-bold py-4 px-6 rounded"
          >
            Withdraw
          </label>
        </div>
      </div>
    </div>
  );
}

export default Wallet;
