import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function ReasonModal({ reasonData }) {
  const [showPassword, setShowPassword] = useState(false);
  const [bankData, setBankData] = useState([]);

  const [loading, setLoading] = useState();

  return (
    <>
      {/* <label htmlFor="my-modal-5" className="btn modal-button">open modal</label> */}

      <input type="checkbox" id="reason-modal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box w-11/12  h-auto">
          {/* <label for="reason-modal" class="   absolute left-2 top-2"> */}
          <label
            htmlFor="reason-modal"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          {/* </label> */}

          <div className="font-semibold text-gray-600 text-center  pb-4 text-lg md:text-2xl">
            Reason for Disapproval
          </div>
          <div className="text-center bg-slate-300 rounded-lg px-5 py-3">
            {reasonData?.pr_reason}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReasonModal;
