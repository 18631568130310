import React from "react";
import Navbar from "../components/header/Navbar";
import GiftCardForm from "../components/dashboard/GiftCardForm";
import Footer from "../components/footer/Footer";

function Policy() {
  return (
    <>
      <div class="relative h-[317px]  w-full text-center bg-cover bg-center bg-[#034B5E]">
        <main class="  pt-40 ">
          <div className="text-center font-black text-white text-3xl lg:text-4xl">
            Privacy Policy
          </div>
        </main>
      </div>
      <div className="max-w-[1239px] text-[#474747] font-light text-justify py-6 px-3 mx-auto text-lg ">
        <div>
          Ideal Gift cards To Naira Exchange believes in providing excellent
          service and strives to be sincere and transparent in its approach to
          customer service. Your confidence in us is of paramount importance to
          us and we would never place our personal growth before your interest.
          This Policy aims to minimize instances of customer complaints,
          grievances, and disputes through a channelized approach of review and
          redress. Your feedback will help us in identifying shortcomings in our
          product features and service delivery. Your satisfaction is our main
          objective in our quest to build a healthy customer relationship and
          deliver excellent service. Ideal Gift cards To Naira Exchange has
          developed a procedure for the prompt attention to the grievances of
          its customers with respect to various issues by setting up a “customer
          support” and “a dispute resolution mechanism”. The following are the
          foundational principles of this policy:
        </div>
        <div className="py-2">TRANSPARENCY:</div>
        Prompt, courteous and timeous response to all queries, inquiries, and
        complaints; and Constantly developing new and smarter mechanisms to
        receive and address customer grievances. The details of the dispute
        resolution mechanism are placed in the domain of public knowledge. A
        dispute may occur as a result of several reasons. It may be because of
        the difference in the service expected and the service delivered. A
        dispute may also arise as a result of technical or non-technical errors
        in the system or at times due to human inadvertence or error. Ideal Gift
        cards To Naira Exchange provides a user-friendly platform to all its
        customers to file a complaint/ share feedback if they are disappointed
        by the services rendered. Customers can give their complaint/ feedback
        in sending an email to [email protected] If the user's grievance is not
        resolved within the stipulated time frame, or if the user is not
        completely satisfied with the solution provided by Ideal Gift cards To
        Naira Exchange, then they can approach our dispute resolution system
        with their complaint or explore other legal avenues available for
        dispute resolution. To make igcex.ng dispute resolution system more
        meaningful and effective, a structured system has been put in place.
        This system will ensure that the complaints are redressed seamlessly and
        well within the stipulated time frame. THE EXPERTISE OF STAFF IN
        HANDLING COMPLAINTS: Our staff undergo regular training to ensure that
        consumers' queries and grievances are handled properly. They are trained
        to work in a manner that enhances consumer trust and confidence in us.
        Our objective to achieve maximum customer satisfaction can be witnessed
        in both the operations as well as in customer communications. We analyze
        consumer queries and complaints and ensure the removal of such
        complaints from the root. This helps in improving the overall quality of
        the service levels gradually. Introduction to our Dispute Resolution
        System The users' experience is our focus and this is why we devote
        ourselves to listen to our users and take their concerns seriously. We
        positively analyze and scrutinize how you would feel from the time you
        make requests for our services until the time you're done to evolve and
        enhance our services. We ensure a simply incredible and user-friendly
        experience to all our customers and in case of any complaint or concern,
        we do everything we can to fix them. How to reach our support desk: For
        Exchange, Payment or Related Issues Step 1 You can send an email to
        [Support@igcex.ng] You can also call us on Step 2 If the resolution you
        received does not meet your expectations, please inform us at
        [Support@igcex.ng] . FOR LEGAL, COMPLIANCE, OR RELATED ISSUES: Step 1
        You can reach us by calling Step 2 If the resolution you received does
        not meet your expectations, please inform us at Support@igcex.ng. It is
        also recommended that the customer provides a complaint reference number
        provided by our customer care team in all further communication with us
        regarding a particular issue. This enables us to get more details about
        the customer and the query quickly and helps in resolving the query
        faster. Suitable timelines have been set for every complaint depending
        on the nature of queries and the investigations lead time which would be
        involved in resolving the same. I acknowledge that I have read these
        terms and conditions and I agree to follow the guidelines stated by
        igcex.ng during any trading or transactions on the website or App.
      </div>
    </>
  );
}

export default Policy;
