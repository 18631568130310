import axios from "axios";
import React, { useEffect, useState } from "react";
import PinModal from "./modals/PinModal";
import SetPinModal from "./modals/SetPin";

function Security() {
  const [profileData, setProfileData] = useState();
  const [showPinModal, setShowPinModal] = useState(true);
  const [showSetPinModal, setShowSetPinModal] = useState(true);
  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = () => {
    const token = localStorage.getItem("giftToken");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}api/v1/customer_profile`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        setProfileData(response.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div>
      {showPinModal && <PinModal setShowPinModal={setShowPinModal} />}
      {showSetPinModal && (
        <SetPinModal
          setShowSetPinModal={setShowSetPinModal}
          getProfileData={getProfileData}
        />
      )}
      <div className="max-w-[432px] h-[530px] rounded-xl bg-white px-3">
        <div className="py-4 px-1 font-black text-2xl">
          Security
          <div className="font-light text-sm">
            Improve your account security
          </div>
        </div>
        {profileData?.settxpin ? (
          <div className="flex py-5 justify-between items-center">
            <div>
              <div className="inline-flex items-center">
                <div>
                  {" "}
                  <img src="/side-icon.svg" />
                </div>
                <div className="text-lg pl-2 text-gray-400 font-bold">
                  Update Pin
                  <div className="text-sm text-gray-400 font-light">
                    * * * * * * *
                  </div>
                </div>
              </div>
            </div>
            <div>
              <label
                onClick={() => setShowPinModal(true)}
                htmlFor="my-modal-3"
                className="btn btn-sm rounded-3xl text-sm btn-info bg-[#CEEDFF] modal-button"
              >
                Edit
              </label>
            </div>
          </div>
        ) : (
          <div className="flex py-5 justify-between items-center">
            <div>
              <div className="inline-flex items-center">
                <div>
                  {" "}
                  <img src="/side-icon.svg" />
                </div>
                <div className="text-lg pl-2 text-gray-400 font-bold">
                  Set Pin
                  <div className="text-sm text-gray-400 font-light">
                    * * * * * * *
                  </div>
                </div>
              </div>
            </div>
            <div>
              <label
                onClick={() => setShowSetPinModal(true)}
                htmlFor="my-modal-13"
                className="btn btn-sm rounded-3xl text-sm btn-info bg-[#CEEDFF] modal-button"
              >
                Edit
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Security;
