import React from "react";
import About from "../components/about/About";
import HowToTrade from "../components/about/HowToTrade";
import Cards from "../components/cards/Cards";
import GiftCards from "../components/cards/GiftCards";
import Footer from "../components/footer/Footer";
import Hero from "../components/header/Hero";
import { IntercomProvider, useIntercom } from "react-use-intercom";
function LandingPage() {
  const { boot, shutdown, hide, show, update } = useIntercom();

  return (
    <div>
      <Hero />
      <div className="md:pt-24 pt-10">
        <Cards />
      </div>
      <div className="md:pt-24 pt-10 px-4">
        <About />
      </div>
      <div className="md:pt-24 py-10">
        <HowToTrade />
        <GiftCards />
      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
