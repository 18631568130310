import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function BankDetailModal({ getBankDetail, setShowModal }) {
  const [errorMessage, setErrorMessage] = useState();
  const [bankData, setBankData] = useState([]);
  const [loading, setLoading] = useState();
  const [bankId, setBankId] = useState();
  const [accountName, setAccountName] = useState();
  const [loadingName, setLoadingName] = useState();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    getBankData();
  }, []);

  const getBankData = () => {
    const token = localStorage.getItem("giftToken");

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}api/v1/bank_list`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        setBankData(response.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onSubmit = (data) => {
    setLoading("loading");
    const token = localStorage.getItem("giftToken");
    const PAYLOAD = {
      ...data,
      bank_code: bankId.code,
      bank_name: bankId.name,
      account_no: accountName?.account_number,
      account_name: accountName?.account_name,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API}api/v1/customer_bank_update`,
      headers: { Authorization: `Bearer ${token}` },
      data: { ...PAYLOAD },
    };

    axios(config)
      .then(function (response) {
        setLoading("");
        getBankDetail();
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setShowModal(false);
      })
      .catch(function (error) {
        if (error?.response?.data?.error) {
          setLoading("");
          toast.error(error?.response?.data?.error[0], {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        } else {
          setLoading("");
          toast.error(error?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const getBankDetails = (data) => {
    setLoadingName("Please waiting...");
    const token = localStorage.getItem("giftToken");
    const PAYLOAD = {
      bank_code: bankId.code + "",
      account_no: data + "",
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API}api/v1/verify_account_no`,
      headers: { Authorization: `Bearer ${token}` },
      data: { ...PAYLOAD },
    };

    axios(config)
      .then(function (response) {
        setLoadingName();
        if (response?.data?.message == "Invalid account number provided") {
          setAccountName();
          setErrorMessage("Invalid Account Number");
        } else {
          setAccountName(response?.data?.data);
          setErrorMessage();
        }
      })
      .catch(function (error) {
        if (error?.response?.data?.error) {
          setLoadingName();
          toast.error(error?.response?.data?.error[0], {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        } else {
          setLoadingName();
          toast.error(error?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  const handleChange = (e) => {
    const parsedValue = JSON.parse(e.target.value);
    setBankId(parsedValue);
  };
  const handleAccountNumber = (e) => {
    if (e.target.value.length == 10) {
      getBankDetails(e.target.value);
    }
  };
  return (
    <>
      {/* <label htmlFor="my-modal-5" className="btn modal-button">open modal</label> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <input type="checkbox" id="my-modal-14" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box w-11/12 max-w-[662px] h-[650px]">
          <label for="my-modal-14" class="   absolute left-2 top-2">
            <img src="/arrow-right.svg" className="h-8 w-8 font-bold" />
          </label>
          <div className="font-black text-center text-gray-400 py-4 text-2xl">
            Set Bank Details
          </div>
          <div></div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="py-5">
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  <div class="relative">
                    <select
                      class="block appearance-none w-full bg-white border border-gray-700 text-gray-700 py-5 px-4 pr-8 rounded-2xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-state"
                      onChange={handleChange}
                    >
                      <option disabled selected>
                        Select Bank
                      </option>
                      {bankData?.map((data, i) => (
                        <option value={JSON.stringify(data)}>
                          {data?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div class="flex   flex-wrap -mx-4 mb-2">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    Account Number
                  </label>

                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-email"
                    type="text"
                    placeholder="Account Number"
                    required
                    onChange={handleAccountNumber}
                    // {...register("account_no")}
                  />
                </div>
              </div>

              <div class="flex   flex-wrap -mx-4 mb-2">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    Account Name
                  </label>

                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-email"
                    type="text"
                    placeholder="Account Name"
                    required
                    disabled
                    value={
                      loadingName || accountName?.account_name || errorMessage
                    }
                    {...register("account_name")}
                  />
                </div>
              </div>

              {/* <div class="flex   flex-wrap -mx-4 mb-2">
                <div class="w-full relative px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-city"
                  >
                    Bank Name
                  </label>

                  <input
                    class="-appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded-xl py-5 px-4  mb-3 leading-tight focus:outline-none"
                    id="grid-email"
                    type="text"
                    placeholder="Bank Name"
                    required
                    {...register("bank_name")}
                  />
                </div>
              </div> */}
            </div>
            <div className="text-center w-full">
              <button className={`${loading} btn  lg:w-1/2 w-full`}>
                Update
              </button>
            </div>
          </form>

          {/* <div className="modal-action">
            <label htmlFor="my-modal-5" className="btn">
              Yay!
            </label>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default BankDetailModal;
