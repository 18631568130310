import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function ListCards() {
  const [cardsList, setCardsList] = useState([]);
  const navigate = useNavigate();
  const getCardList = () => {
    const token = localStorage.getItem("giftToken");
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API}api/v1/list-gift-cards`,
      headers: { Authorization: `Bearer ${token}` },
    };

    axios(config)
      .then(function (response) {
        setCardsList(response.data?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getCardList();
  }, []);

  return (
    <div className="md:pt-20  pt-10 ">
      <div className="max-w-6xl mx-auto">
        <div className="grid md:grid-cols-3  grid-cols-2 md:gap-4 gap-3  lg:px-0 px-4">
          {cardsList?.map((data, i) => (
            <Link to={`/dashboard/sell/start-trading/${data?.gc_id}`}>
              <div key={i}>
                <img src={data?.gc_image} />
                <p className="text-center text-[#474747] md:py-3 font-normal py-1">
                  {data?.gc_name}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>

      <div onClick={() => navigate(-1)} className="text-center px-3 py-5">
        <button class="btn bg-[#2F2E41] btn-lg w-full  md:w-96   text-white text-xl -btn-warning">
          <img src="/arrow-left.svg" />
          Go Back
        </button>
      </div>
    </div>
  );
}

export default ListCards;
